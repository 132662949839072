import { styled } from "@mui/material/styles";
import { Button, colors } from "@mui/material";

const FloatButton = styled(Button)({
    border: `1px solid  #3959A5`,
    backgroundColor: "#3959A5",
    color: "#3959A5",
    fontSize: "8px",
    borderRadius: "5px",
    minWidth: "unset",
    position: "absolute",
    right: "10px",
    top: "25px",
    "&.active": {
        backgroundColor: "white",
        border: `1px solid  #3959A5`,
    },
    "& .MuiSvgIcon-fontSizeMedium": {
        color: "white",
        fontSize: "3em",
    },
    "&:hover": {
        backgroundColor: "white",
        color: "#3959A5",
    },
    "&:hover .MuiSvgIcon-fontSizeMedium": {
        color: "#3959A5",
    },
    "&.active .MuiSvgIcon-fontSizeMedium": {
        color: "#3959A5",
    },
});

export default FloatButton;