import { Grid, Stack, Typography, Box } from "@mui/material";
import React, { useContext, useEffect, memo, useState } from "react";
import Select from "../../../../components/Select/Select";
import HelpIcon from '@mui/icons-material/Help';
import {
  StyledTextFieldReadOnly,
} from "../../components/styled.components";
import {
  InputTextCurrency,
  InputNumberWithTrailEnd,
} from "../../../../components/Base/Inputs";
import { CalculationContext } from "../../context/CalculationContext";
import { useGetBatteriesById } from "../../api/CalculationsApi";
import FloatButton from "../../../../components/FloatButton/FloatButton";
import { PAGO, validateNumber } from "../../calculationsUtils";

const BatterySection = memo(({ color, data, auxQuotation, updatetab, isVisibility }) => {
  const {
    state: { listBatteriesWithWarranties, battery, idBatteryWarranty, baterryCost, pricePayAux, exchangeRate },
    dispatch,
    setIsChangeBattery,
  } = useContext(CalculationContext);

  const { data: batterySelect } = useGetBatteriesById(
    battery[auxQuotation]?.batteryId
  );

  const [visibleFloat, setvisibleFloat] = useState(false);

  useEffect(() => {
    if (baterryCost?.length > 0 && baterryCost[auxQuotation] && idBatteryWarranty && !isVisibility) {
      setIsChangeBattery(true)
    }
  }, [baterryCost[auxQuotation], isVisibility])


  useEffect(() => {
    if (listBatteriesWithWarranties && !battery[auxQuotation] && !isVisibility ) {
      dispatch({
        type: "SET_BATTERY",
        payload: {
          battery: listBatteriesWithWarranties[0]?.value,
          index: auxQuotation,
        },
      });
    } 
  }, [listBatteriesWithWarranties, dispatch, auxQuotation, baterryCost, battery, isVisibility]);

  useEffect(() => {
    if (batterySelect && idBatteryWarranty && !isVisibility) {
      dispatch({
        type: "SET_BATTERY_COST",
        payload: {
          batteryCost: batterySelect,
          index: auxQuotation,
        },
      });
    }
  }, [batterySelect, dispatch, auxQuotation, idBatteryWarranty, isVisibility]);

  const handleChangeBattery = (value) => {
    dispatch({
      type: "SET_BATTERY",
      payload: {
        battery: value,
        index: auxQuotation,
      },
    });
  };
  
  const onChangeValue = (name, value) => {
    const valorMinimo = name === "periodMonths" ? 1000 : 100;
    const newValue = Math.min(valorMinimo, Math.max(0, value));

    const aux = pricePayAux.map((element, index) => {
      // Solo se actualiza la sección actual
      if (index === auxQuotation + 1) {
        // Clonación superficial y parcial de las propiedades para evitar referencias compartidas
        const newElement = {
          ...element,
          details: [...element.details]
        };

        // Clonar la sección correspondiente (suponiendo que updatetab es el índice correcto)
        newElement.details[updatetab] = {
          ...newElement.details[updatetab],
          receiptCalculation: [...newElement.details[updatetab].receiptCalculation]
        };

        // Clonar el objeto de cálculo
        const auxCalculation = { ...newElement.details[updatetab].receiptCalculation[0] };

        if (name === "initialExpensePercent") {
          auxCalculation.initialExpensePercent = newValue;
          const initialExpense = (newValue / 100) * auxCalculation.leasingPrice;
          auxCalculation.initialExpense = initialExpense;
          auxCalculation.initialPaymentUSD = initialExpense / exchangeRate
          auxCalculation.initialPaymentMXN = initialExpense
        }

        if (name === "periodMonths") {
          auxCalculation.periodMonths = newValue;
        }

        if (name === "residualValuePercent") {
          auxCalculation.residualValuePercent = newValue;
          const residualValue = (newValue / 100) * auxCalculation.leasingPrice;
          auxCalculation.residualValue = residualValue;

          auxCalculation.finalPaymentMXN = residualValue;
          auxCalculation.finalPaymentUSD = auxCalculation.finalPaymentMXN / exchangeRate;
        }

        const annualRate = auxCalculation.annualInterestRate / 100
        const periodInMonths = auxCalculation.periodMonths
        const initialCost = (auxCalculation.leasingPrice - auxCalculation.initialExpense) * -1
        const residualValue = auxCalculation.residualValue
        auxCalculation.monthlyPaymentMXN = validateNumber(PAGO(annualRate, periodInMonths, initialCost, residualValue));
        auxCalculation.monthlyPaymentUSD = auxCalculation.monthlyPaymentMXN / exchangeRate;

        const totalPaymentMountly = (auxCalculation.monthlyPaymentMXN * auxCalculation.periodMonths)
        auxCalculation.total = auxCalculation.finalPaymentMXN + auxCalculation.initialPaymentMXN + totalPaymentMountly;
        auxCalculation.netProfit = Math.round(((auxCalculation.total / (auxCalculation.requiredInvestmentSAEMXN + auxCalculation.installationCost + auxCalculation.civilWorkCost + auxCalculation.otherExpenses)) - 1) * 100);

        // Asigna la copia modificada en la posición correcta
        newElement.details[updatetab].receiptCalculation[0] = auxCalculation;
        return newElement;
      }

      return element;
    });
    dispatch({ type: 'SET_PRICE_PAY_AUX', payload: aux });
  };

  const handleFloatButton = (element) => {
    setvisibleFloat(!visibleFloat);
  }

  const formatDecimalValue = (value) => {
    if (value !== undefined && value !== null) {
      const numberValue = parseFloat(value);
      const truncatedValue = Math.floor(numberValue * 10000) / 10000;

      return truncatedValue.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 4
      });
    }
  }

  const getDefaultBattery = () => {
    if (!isVisibility) {
      return battery[auxQuotation];
    } else {
      if (data && data.warrantyId) {
        var aux = listBatteriesWithWarranties.filter((element) => element.value.warrantyId === data.warrantyId);
        return (aux.length > 0 && aux[0].value) ? aux[0].value : {};
      } else {
        return {}; // Objeto vacío
      }
    }
  }
  return (
    <Grid container spacing={2}>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
          <Stack spacing={1}>
            <Typography variant="subtitle2" gutterBottom>
              Tipo de batería
            </Typography>
            <Select
              options={listBatteriesWithWarranties}
              searchable
              defaultValue={getDefaultBattery()}
              onChange={handleChangeBattery}
              style={{ height: "56px" }}
              disabled={isVisibility}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Stack spacing={1}>
            <Typography variant="subtitle2" gutterBottom>
              Precio Batería (USD x kWh)
            </Typography>
            <StyledTextFieldReadOnly
              fullWidth
              value={isVisibility ? data?.batteryCost : baterryCost[auxQuotation] || 0}
              inputProps={{ readOnly: true }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Stack spacing={1}>
            <Typography variant="subtitle2" gutterBottom>
              Inversión SAE Requerida
            </Typography>
            <Stack direction="row" spacing={2}>
              <InputTextCurrency
                value={data?.requiredInvestmentSAEUSD ?? 0}
                name="requiredInvestmentSAEUSD"
                currency="USD"
                readOnly
              />

              <InputTextCurrency
                value={data?.requiredInvestmentSAEMXN ?? 0}
                name="requiredInvestmentSAEMXN"
                currency="MXN"
                readOnly
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ position: 'relative' }}>
        <FloatButton onClick={handleFloatButton} className={visibleFloat ? 'active' : 'hiddeado'}>
          <HelpIcon></HelpIcon>
        </FloatButton>
        <Box
          sx={{
            backgroundColor: `${color}60`,
            padding: 4,
            borderRadius: "4px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Stack spacing={3}>
                <Typography variant="subtitle1" fontWeight={600}>
                  Precio contado (USD)
                </Typography>

                <Stack spacing={2}>

                  <Stack spacing={1}>
                    <Typography variant="subtitle2">MXN Inversión</Typography>
                    <InputTextCurrency
                      value={formatDecimalValue(data?.roiMXN)}
                      name="roiMXN"
                      readOnly
                      currency="MXN"
                    />
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="subtitle2">USD Inversión</Typography>
                    <InputTextCurrency
                      value={formatDecimalValue(data?.roiUSD)}
                      name="roiUSD"
                      readOnly
                      currency="USD"
                    />
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="subtitle2">Meses</Typography>
                    <InputNumberWithTrailEnd
                      name={"monthllyRoi"}
                      unitValue={"Meses"}
                      value={formatDecimalValue(data?.monthllyRoi || 0)}
                      readOnly
                      allowDecimals
                    />
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="subtitle2">Años</Typography>
                    <InputNumberWithTrailEnd
                      name={"annualRoi"}
                      unitValue={"Años"}
                      value={formatDecimalValue(data?.annualRoi || 0)}
                      readOnly
                      allowDecimals
                    />
                  </Stack>
                  {visibleFloat ?
                    <Stack spacing={1}>
                      <Typography variant="subtitle2">Utilidad MXN</Typography>
                      <Stack direction={"row"} spacing={2}>
                        <InputTextCurrency
                          value={formatDecimalValue(data?.utilityMxn || 0)}
                          name="utilityMxn"
                          readOnly
                          currency="MXN"
                          show={visibleFloat}
                        />
                        <InputNumberWithTrailEnd
                          name={"utilityMxnPercent"}
                          unitValue={"%"}
                          value={formatDecimalValue(data?.utilityMxnPercent || 0)}
                          onChange={onChangeValue}
                          readOnly
                        />
                      </Stack>
                    </Stack> :
                    ''
                  }
                </Stack>
              </Stack>

            </Grid>
            <Grid item xs={4}>
              <Stack spacing={3}>
                <Typography variant="subtitle1" fontWeight={600}>
                  Leasing (USD)
                </Typography>

                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Typography variant="subtitle2">Precio</Typography>
                    <InputTextCurrency
                      value={formatDecimalValue(data?.leasingPrice)}
                      name="leasingPrice"
                      readOnly
                      currency="MXN"
                    />
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="subtitle2">Gasto Inicial</Typography>

                    <Stack direction={"row"} spacing={2}>
                      <InputNumberWithTrailEnd
                        name={"initialExpensePercent"}
                        unitValue={"%"}
                        value={data?.initialExpensePercent}
                        onChange={onChangeValue}
                        readOnly={isVisibility ? true : false}
                      />
                      <InputTextCurrency
                        value={formatDecimalValue(data?.initialExpense)}
                        name="initialExpense"
                        readOnly
                        currency="MXN"
                      />
                    </Stack>
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="subtitle2">Periodo (meses)</Typography>
                    <InputNumberWithTrailEnd
                      name={"periodMonths"}
                      unitValue={""}
                      value={data?.periodMonths}
                      readOnly={isVisibility ? true : false}
                      onChange={onChangeValue}
                    />
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="subtitle2">Valor residual</Typography>
                    <Stack direction={"row"} spacing={2}>
                      <InputNumberWithTrailEnd
                        name={"residualValuePercent"}
                        unitValue={"%"}
                        value={data?.residualValuePercent}
                        onChange={onChangeValue}
                        readOnly={isVisibility ? true : false}
                      />

                      <InputTextCurrency
                        value={formatDecimalValue(data?.residualValue)}
                        name="residualValue"
                        readOnly
                        currency="MXN"
                      />
                    </Stack>
                  </Stack>

                  <Typography variant="subtitle2" fontWeight={600}>
                    Duración 12 meses
                  </Typography>

                  <Stack spacing={1}>
                    <Typography variant="subtitle2">Pago inicial</Typography>
                    <Stack direction={"row"} spacing={2}>
                      <InputTextCurrency
                        value={formatDecimalValue(data?.initialPaymentUSD)}
                        name="initialPaymentUSD"
                        readOnly
                        currency="USD"
                      />

                      <InputTextCurrency
                        value={formatDecimalValue(data?.initialPaymentMXN)}
                        name="initialPaymentMXN"
                        readOnly
                        currency="MXN"
                      />
                    </Stack>
                  </Stack>
                  <Stack spacing={1}>
                    <Typography variant="subtitle2">Mensualidad</Typography>
                    <Stack direction={"row"} spacing={2}>
                      <InputTextCurrency
                        value={formatDecimalValue(data?.monthlyPaymentUSD)}
                        name="monthlyPaymentUSD"
                        readOnly
                        currency="USD"
                      />

                      <InputTextCurrency
                        value={formatDecimalValue(data?.monthlyPaymentMXN)}
                        name="monthlyPaymentMXN"
                        readOnly
                        currency="MXN"
                      />
                    </Stack>
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="subtitle2">Pago final</Typography>
                    <Stack direction={"row"} spacing={2}>
                      <InputTextCurrency
                        value={formatDecimalValue(data?.finalPaymentUSD)}
                        name="finalPaymentUSD"
                        readOnly
                        currency="USD"
                      />

                      <InputTextCurrency
                        value={formatDecimalValue(data?.finalPaymentMXN)}
                        name="finalPaymentMXN"
                        readOnly
                        currency="MXN"
                      />
                    </Stack>
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="subtitle2">Total</Typography>
                    <Stack direction={"row"} spacing={2}>
                      <InputTextCurrency
                        value={formatDecimalValue(data?.total)}
                        name="total"
                        readOnly
                        currency="MXN"
                      />
                    </Stack>
                  </Stack>
                  {visibleFloat ?
                    <Stack spacing={1}>
                      <Typography variant="subtitle2">Utilidad neta</Typography>
                      <Stack direction={"row"} spacing={2}>
                        <InputNumberWithTrailEnd
                          name={"netProfit"}
                          unitValue={"%"}
                          value={Math.round(data?.netProfit)}
                          onChange={onChangeValue}
                          readOnly
                          show={visibleFloat}
                        />
                      </Stack>
                    </Stack> :
                    ''
                  }
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
});

export default BatterySection;
