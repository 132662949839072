import CustomTemplate from "../../components/CustomTemplate/CustomTemplate"
import ExchangeRateTabPanel from "./sections/exchangeRate"
import RegionSettingsTabPanel from "./sections/regionSettings"
import IndirectCostsTabPanel from "./sections/IndirectCosts"
import { TabsGeneric } from "../Calculations/components/DetailPay/TabPanel"
import { useState } from "react"

export const ExchangeRate = () => {
    const [updatetab, setUpdateTab] = useState(0);

    const tabsData = [
        { tab: 'Tipo de cambio', key: 'tipoDeCambio' },
        { tab: 'Conf. de región', key: 'confDeRegion' },
        { tab: 'Costos indirectos', key: 'costosIndirectos' },
    ];

    const tabPanelData = [
        <ExchangeRateTabPanel/>,
        <RegionSettingsTabPanel/>,
        <IndirectCostsTabPanel/>,
    ];

    return (
        <>
            <CustomTemplate title="Configuraciones Generales" hasButtonBack>

            <TabsGeneric
                tabs={tabsData.map(item => item.tab)}
                tabvalue={updatetab}
                setUpdateTab={setUpdateTab}
                components={tabPanelData}
            />
            </CustomTemplate>
        </>
    )
}