import Axios from 'axios';
import { RUTA_API_BACK } from '../constans/index';
import { redirect } from "react-router-dom";
import { ShowMessage } from '../utils/utils';
import StoreUser from '../storeUser';

const storeUser = new StoreUser();

const authRequestInterceptor = (config) => {
    const session = storeUser.getFromLocalStorage();
    if (session?.token) {
        config.headers.authorization = `Bearer ${session.token}`;
    }
    config.headers.Accept = 'application/json';
    return config;
}

const responseInterceptor = ({ status, data }) => {
    if (status === 200) {
        return data;
    } else if (status === 201) {
        return data;
    } else if (status === 204) {
        return true;
    } else if (status === 404) {
        const message = data?.message || "No se encontraron resultados";
        ShowMessage(message, "error");
        return null;
    } else {
        if (status !== null) {
            ShowMessage("Error desconocido. Favor de contactar a soporte.", "error");
        }
        return null;
    }
    return data;
}

const errorResponseInterceptor = (error) => {
    const { status, data } = error.response;
    if (status === 500) {
        if (error.request.responseURL.endsWith('process-electricity-bill')) {
            if (error.response.data?.error && error.response.data?.error.includes("tarifa")) {
                ShowMessage(error.response.data.error, "error");
            } else {
                ShowMessage("El archivo o imagenes no se pudieron procesar, intente con una mejor resolución.", "error");
            }
        } else {
            const message = data?.error || "Hubo un error en el servidor, favor de contactar a soporte";
            ShowMessage(message, "error");
        }
        return JSON.stringify(error.response);
    } else if (status === 401) {
        ShowMessage("Tu sesion a expirado favor de ingresar de nuevo", "error");
        setTimeout(() => {
            window.location.href = "/auth/signin";
        }, 2000);
        return null;
    } else if (status === 400) {
        ShowMessage("Ocurrió un error en los datos enviados", "error");
        return null;
    } else if (status === 404) {
        const message = data?.message || "No se encontraron resultados";
        ShowMessage(message, "warning");
        return null;
    }

    return Promise.reject(error);
}
//
export const axiosServiceV1 = Axios.create({
    baseURL: RUTA_API_BACK,
});

axiosServiceV1.interceptors.request.use(authRequestInterceptor);
axiosServiceV1.interceptors.response.use(responseInterceptor, errorResponseInterceptor);

export default axiosServiceV1;