import { Grid, Typography } from "@mui/material"
import { InputText } from "../../../components/Base/Inputs"
import Select from "../../../components/Select/Select"
import { PrimaryButton, SecondaryButton } from "../../../components/Base/Buttons/Buttons";
import { ListNodes } from "./ListNodes";
import { ProjectionsContext } from "../../../utils/context/projectionsContext";
import { useState, useEffect, useContext } from "react";
import { Projections } from "./Projections";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../../hooks/useForm";
import { useCreateProjections, useGetDownloadTime, useGetInvestmentProjection, useGetProjectionsById } from "../api/Projectionsapi";
import { useGetExchangeRate } from "../../ExchangeRate/api/ExchangeRateApi";
import { ConfirmationModal } from "../../../components/Modal/ConfirmationModal";
import { AlphanumericValidation, COMPONENTS } from "../../../utils/utils";
import { CustomModal } from "../../../components/Modal/CustomModal";
import { ContentSection } from "../../../components/Base/TitleSection/TitleSection.Style";
import { StyledNameProjection } from "./Projections.Styled";

export const NewProjections = () => {
    const history = useNavigate();
    const { id } = useParams();
    const exchangeRate = useGetExchangeRate();
    const { state, disabled, handleValues, setSelectNode, setSelectBatterie, reset, setDisabled } = useContext(ProjectionsContext);
    const [options, setOptions] = useState([]);
    const createProjection = useCreateProjections();
    const optionsTimeDownloadQuery = useGetDownloadTime();
    const projectionById = useGetProjectionsById(id);
    const [open, setOpen] = useState({ open: false, component: null, message: null, status: null });
    const [loading, setLoading] = useState(false);

    const { setPropertiesValue, handleSubmit, handleChange, data: calculationNode, errors, setErrors } = useForm({
        initialValues: null,
        validations: {
            megaWatts: {
                required: { message: "Este campo es requerido" },
                custom: {
                    isValidFn: (value) => {
                        const valueAsString = String(value);
                        return valueAsString.length <= 10;
                    },
                    message: "Solo se permiten un máximo de 10 carácteres"
                },
            },
            timeDownloadId: {
                required: { message: "Este campo es requerido" }
            },

        },
        onSubmit: async () => {
            investmentProjectionQuery.refetch()
        }
    });
    const investmentProjectionQuery = useGetInvestmentProjection({ megaWatts: calculationNode?.megaWatts, timeDownload: calculationNode?.timeDownloadId });

    const handleSetNameProjection = () => {
        if (state?.megaWatts && state?.timeDownloadId && state?.nodeId && state?.exchangeRate && state?.nodeDate && state?.batteryId) {
            setOpen({ open: true, component: COMPONENTS.CONFIRM, message: "", status: "" });
        } else {
            setOpen({ open: true, component: COMPONENTS.ERROR, message: "No se puede guardar sin antes realizar la proyección", status: "warning" });
        }
    }

    const handleSave = async () => {
        if (!state?.nameProjection || state?.nameProjection.trim() === "") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                nameProjection: "Campo requerido",
            }));
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            nameProjection: null,
        }));

        setLoading(true);

        const auxDataProjection = JSON.parse(JSON.stringify(state));
        auxDataProjection.IdConfigProjection = auxDataProjection.exchangeRate?.id;
        delete auxDataProjection.listNodes;
        delete auxDataProjection.exchangeRate;
        const result = await createProjection.mutateAsync(auxDataProjection);
        const auxResult = result.projectionId ? result : JSON.parse(result);
        if (auxResult?.projectionId) {
            setOpen({ open: true, component: COMPONENTS.SUCCESS, message: "Se guardó la Proyección de inversión con éxito.", status: "success" });
        } else if (auxResult?.status !== 200) {
            setOpen({ open: true, component: COMPONENTS.ERROR, message: auxResult?.data?.error, status: "error" });
            setLoading(false);
        }
    }

    const getDefaultValue = () => {
        if (calculationNode?.timeDownloadId && disabled) return calculationNode?.timeDownloadId;
        return null
    }

    useEffect(() => {
        if (optionsTimeDownloadQuery.isSuccess && optionsTimeDownloadQuery.data) {
            const auxData = [];
            optionsTimeDownloadQuery.data.map((value) => {
                auxData.push({
                    value: value.value,
                    label: value.description
                })
            });
            setOptions(auxData);
        }
    }, [optionsTimeDownloadQuery.data, state?.timeDownloadId]);

    useEffect(() => {
        if (investmentProjectionQuery.isSuccess && investmentProjectionQuery.data && calculationNode.megaWatts && calculationNode.timeDownloadId && exchangeRate.isSuccess && exchangeRate.data && !id) {
            handleValues({
                ...state,
                megaWatts: parseInt(calculationNode.megaWatts),
                timeDownloadId: calculationNode.timeDownloadId,
                exchangeRate: exchangeRate.data,
                listNodes: investmentProjectionQuery.data,
                nodeId: null,
                nodeDate: "",
                charge: null,
                download: null,
                difference: null,
                loadingSchedule: null,
                downloadSchedule: null,
                perDay: null,
                perMonth: null,
                perYear: null,
                costKw: null,
                batteryId: null,
                warrantyId: null,
                deduction: null,
                investmentCostUSD: null,
                investmentCostMSN: null,
                discountedNodeYear: null,
                returnInvestmentYear: null,
                returnInvestmentMonth: null,
                nameProjection: null,
            });
            setSelectNode(null);
            setSelectBatterie(null);
        } else if (investmentProjectionQuery.isSuccess && investmentProjectionQuery.data && calculationNode.megaWatts && calculationNode.timeDownloadId && exchangeRate.isSuccess && exchangeRate.data && id) {
            handleValues({ ...state, listNodes: investmentProjectionQuery.data })
        }
    }, [investmentProjectionQuery.data, exchangeRate.data]);

    useEffect(() => {
        if (projectionById.isSuccess && projectionById.data) {
            handleValues(projectionById.data);
            setPropertiesValue({
                megaWatts: projectionById.data.megaWatts,
                timeDownloadId: projectionById.data.timeDownloadId
            });
            setSelectNode(projectionById.data.nodeId);
            setSelectBatterie({ batteryId: projectionById.data.batteryId, warrantyId: projectionById.data.warrantyId });
            setDisabled(true);
        }
    }, [projectionById.data]);

    useEffect(() => {
        if (!id) return;

        if (id && !state?.megaWatts && !state?.timeDownloadId) {
            projectionById.refetch();
        }

        if (id && state?.megaWatts && state?.timeDownloadId) {
            investmentProjectionQuery.refetch()
        }

        return () => {
            projectionById.remove();
        }
    }, [id, state?.megaWatts, state?.timeDownloadId])

    useEffect(() => {
        reset();
        investmentProjectionQuery.remove();
    }, [])

    const handleMegaWattsChange = (e) => {
        let value = e.target.value;
        let numericValue = Number(value);
    
        if (numericValue < 0) {
            numericValue = 0;
        }
    
        if (!Number.isInteger(numericValue)) {
            return;
        }
    
        if (value.length <= 10) {
            setPropertiesValue({ megaWatts: numericValue });
            handleValues({ ...state, megaWatts: numericValue });
        }
    };

    return (
        <>
            <Grid container columnSpacing={3}>
                <Grid item xs={4}>
                    <InputText
                        fullWidth
                        type={"number"}
                        title="Ingresa la cantidad de Megawatts para iniciar el cálculo"
                        value={calculationNode?.megaWatts}
                        disabled={id}
                        onChange={handleMegaWattsChange}
                        error={errors && errors.megaWatts}
                        helperText={errors.megaWatts || ""}
                    />
                </Grid>
                <Grid item xs={4} mt={2}>
                    <Typography variant="subtitle2" gutterBottom>Tiempo de descarga (hrs)</Typography>
                    <Select
                        options={options}
                        onChange={(value) => {
                            setPropertiesValue({ timeDownloadId: value })
                            handleValues({ ...state, timeDownloadId: value })
                        }}
                        searchable={true}
                        error={errors && errors.timeDownloadId}
                        helperText={errors.timeDownloadId || ""}
                        isLoading={disabled ? !(disabled && calculationNode?.timeDownloadId) : false}
                        defaultValue={getDefaultValue()}
                        disabled={disabled}
                    />
                </Grid>
                {!id && <Grid item xs={4} marginTop={"5px"} paddingBottom={"7px"} alignContent={"end"}>
                    <Typography variant="subtitle2" gutterBottom></Typography>
                    <SecondaryButton
                        handleClick={async () => await handleSubmit()}
                        text={"Calcular"}
                        bgColor={"#496fee"}
                    />
                </Grid>}
            </Grid>
            <ListNodes />
            <Projections />
            {!id && <Grid container mt={5} justifyContent={"center"} columnSpacing={2}>
                <Grid item xs={4} textAlign={"right"}>
                    <SecondaryButton
                        handleClick={() => { reset(); history(-1); }}
                        text={"Cancelar"}
                    />
                </Grid>
                <Grid item xs={4}>
                    <PrimaryButton
                        handleClick={() => handleSetNameProjection()}
                        text={"Guardar"}
                    />
                </Grid>
            </Grid>}
            {open.open && open.component === COMPONENTS.SUCCESS &&
                <ConfirmationModal
                    open={open.open}
                    onSucces={() => { setOpen({ open: false, component: null, message: null, status: null }); history(-1); }}
                    isAvailableCancel={false}
                    isAvailableAcept={true}
                    message={open.message}
                    status={open.status}
                />
            }
            {open.open && open.component === COMPONENTS.ERROR &&
                <ConfirmationModal
                    open={open.open}
                    onSucces={() => setOpen({ open: false, component: null, message: null, status: null })}
                    isAvailableCancel={false}
                    isAvailableAcept={true}
                    message={open.message}
                    status={open.status}
                />
            }
            {open.open && open.component === COMPONENTS.CONFIRM &&
                <CustomModal
                    title={"¿Deseas guardar la Proyección de inversión?"}
                    open={open.open}
                    labelAccept={"Guardar"}
                    close={() => setOpen({ open: false, component: null, message: null, status: null })}
                    onSuccess={async () => await handleSave()}
                    width="100%"
                    maxWidth="sm"
                    isLoading={loading}
                >
                    <ContentSection container>
                        <StyledNameProjection item xs={12}>
                            <InputText
                                fullWidth
                                type={"text"}
                                title="Nombre de la proyección"
                                value={state?.nameProjection}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (newValue === "" || newValue.length <= 45 && AlphanumericValidation(newValue).isValid) {
                                        handleValues({ ...state, nameProjection: newValue })
                                    }
                                }}
                                error={state && errors?.nameProjection}
                            />
                            {errors?.nameProjection && (
                                <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                                    {errors.nameProjection} 
                                </Typography>
                            )}
                        </StyledNameProjection>
                    </ContentSection>
                </CustomModal>
            }
        </>
    )
}