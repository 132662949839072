import React from "react";
import GenericTable from "../../../../components/DataTable/GenericTable";

const TariffTableDetail = ({ data, isDoublePeriod, quoteId }) => {

  const columnsTable = [
    { id: "concepto", label: "Concepto" },
    { id: "precio", label: "Precio" },
    { id: "dolar", label: "$" },
    { id: "dolarKw", label: "$/KW" },
    { id: "dolarKWh", label: "$/KWh" },
    { id: "total", label: "Importe Total" },
  ];

  const formatPrice = (price, fix) => {
    const numericPrice = parseFloat(price);
    return !isNaN(numericPrice) ? `$ ${numericPrice.toFixed(fix)}` : "-";
};


  const dataTable = data.map((item, index) => ({
    id: index + 1,
    concepto: item.conceptName,
    precio: formatPrice(item?.value ?? item?.price, 4),
    dolar: formatPrice(item.cost, 2),
    dolarKw: formatPrice(item.costPerKw, 2),
    dolarKWh: formatPrice(item.costPerKwh, 2),
    total: formatPrice(item.totalAmount, 2),  
  }));

  return (
    <GenericTable
      columns={columnsTable}
      data={dataTable}
    />
  );
};

export default TariffTableDetail;
