import { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material"
import { styled } from "@mui/material/styles";
import CustomTemplate from "../../components/CustomTemplate/CustomTemplate";
import CustomButton from "../../components/CustomButtonStyled/CustomButton";
import DataTable from "../../components/DataTable/DataTable";
import { useGetAllViewCalculation } from "./api/CalculationsApi";
import { useNavigate } from "react-router-dom";
import { StyledIcon } from "./components/styled.components";
import { IconDetail } from "../../components/Icon";

export const CalculationsContainer = () => {
    const [modelTable, setModelTable] = useState([]);
    const [isUpdated, setIsUpdated] = useState("");
    const history = useNavigate();

    const { data: listCalculations } = useGetAllViewCalculation(isUpdated); 

    const CustomIconButton = styled(IconButton)(({ theme }) => ({
        "& img": {
            borderRadius: "3px",
        },
    }));

    const columns = [
        { id: "id", label: "ID" },
        { id: "name", label: "Nombre de cálculo" },
        { id: "holder", label: "Titular recibo" },
        { id: "numservice", label: "N° de servicio" },
        { id: "invoiceperiod", label: "Periodo factura" },
        { id: "date", label: "Fecha cálculo" },
        { id: "actions", label: "Acciones" },
    ];

    useEffect(() => {
        if (listCalculations && Array.isArray(listCalculations)) {
            const models = listCalculations.map((calc) => {
                return {
                    id: calc.idCalculation,
                    name: calc.calculationName,
                    holder: calc.receiptHolder,
                    numservice: calc.serviceNumber,
                    invoiceperiod: calc.invoicePeriod,
                    date: calc.calculationDate,
                    actions: (
                        <Grid container justifyContent="center" style={{ width: 'auto' }}>
                            <Grid item>
                                <CustomIconButton
                                    color="primary"
                                    size="medium"
                                    onClick={() => history(`detalle/${calc.billId}`)}
                                >
                                    <StyledIcon>
                                        <IconDetail/>
                                    </StyledIcon>
                                </CustomIconButton>
                            </Grid>
                        </Grid>
                    ),
                }
            });
            setModelTable(models);
        }
    }, [listCalculations]);

    return (
        <>
            <CustomTemplate title="Vista de cálculos">
                <Grid container>
                    <Grid item xs={12}>
                        <DataTable
                            columns={columns}
                            data={modelTable}
                            adjustColumn={["actions"]}
                            filterable
                            customBtn={
                                (<Grid item xs={6} container justifyContent={"right"}>
                                    <Grid item>
                                        <CustomButton
                                            variant="outlined"
                                            onClick={() => history(`nuevo`)}
                                        >
                                            Nuevo Cálculo
                                        </CustomButton>
                                    </Grid>
                                </Grid>)
                            }
                        />
                    </Grid>
                </Grid>
            </CustomTemplate>
        </>
    )
}