import React, { useEffect, useState } from 'react';
import { ContentSection } from '../../../components/Base/TitleSection/TitleSection.Style';
import { Grid, InputAdornment, Switch, Typography } from '@mui/material';
import { InputText } from '../../../components/Base/Inputs';
import { PrimaryButton } from '../../../components/Base/Buttons/Buttons';
import { useApplyIndirectCosts, useIndirectCosts } from '../api/ExchangeRateApi';
import { ConfirmationModal } from '../../../components/Modal/ConfirmationModal';
import { TitleSection } from '../../../components/Base/TitleSection';

const IndirectCostsTabPanel = () => {
    const indirectCosts = useIndirectCosts();
    const [costData, setCostdata] = useState([]);
    const ApplyIndirectCosts = useApplyIndirectCosts();
    const [showModalAppliedCost, setshowModalAppliedCost] = useState(false);
    const [message, setMessage] = useState("");
    const collator = new Intl.Collator('en', { sensitivity: 'base' });

    const handleSubmit = () => {
        let updatesuccess = true;
        costData.map((data,index) => {
            const result = onSave(data)
            if(!result){
                updatesuccess = false
            }
        });
        if(updatesuccess){
            setMessage("Se aplicaron los cambios con éxito");
            setshowModalAppliedCost(true);
        } else {
            setshowModalAppliedCost(false);
        }
        indirectCosts.refetch();
    }
    const onSave = async (data) => {
        let toback;
        const result = await ApplyIndirectCosts.mutateAsync(data);
        toback = result ? true : false;
        return (toback);
      }
    useEffect(() => {
        if(indirectCosts.isSuccess && costData.length == 0){
            indirectCosts.data.sort((x, y) => collator.compare(x.description, y.description));
            const auxData = [];
            indirectCosts.data.map((data) => {
                auxData.push({
                    idConfig: data.idConfig,
                    value: data.value,
                    description: data.description,
                    typeValue: isNaN(parseInt(data.typeValue)) ? 0 : parseInt(data.typeValue)

                })
            })
            setCostdata(auxData);
        }
    }, [indirectCosts]);
    
    const handleChange = (index, value) => {
        let numericValue = Number(value);
        if(costData[index]['typeValue'] == "1"){
            numericValue = Math.round(numericValue);
            numericValue = numericValue.toFixed(0);
            if (numericValue < 0 || isNaN(numericValue)) {
                numericValue = 0; 
            } else if (numericValue > 100) {
                numericValue = 100; 
            }
        }else{
            numericValue = numericValue.toFixed(4);
        }
        const newCost = [...costData];
        newCost[index]['value']= Number(numericValue);
        setCostdata(newCost);
    };
    
    const switchType = (index, e) =>{
        let typeDataValue = Number(e.target.checked);
        const newCost = [...costData];
        newCost[index]['typeValue']= parseInt(typeDataValue);
        handleChange(index, costData[index]['value'])
        setCostdata(newCost);
    }
    return (
        <>
            <ContentSection container>
                <Grid container columnSpacing={4}>
                    <TitleSection title={"Costos Indirectos"}/>
                    <Grid item container spacing={4}>
                        {costData.map((data,index) =>
                            <Grid item xs={4} key={index} display={'flex'} alignItems={'flex-end'} >
                                <div className='switch_content'>
                                    <Typography style={{fontSize:'x-small', color:'#00000099'}} >
                                        Cambiar a <br/> {data.typeValue ? 'moneda' : 'porcentaje'}
                                    </Typography >
                                    <Switch key={'swicht_' + data.idConfig}
                                        onClick={(e) => switchType(index, e)}
                                        checked = {data.typeValue ? true : false}
                                    />
                                </div>
                                <InputText
                                    key={'id_' + data.idConfig}
                                    name={'name_' + data.idConfig}
                                    type="number"
                                    title={data.description}
                                    value={data.value}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            {data.typeValue ? '' : '$'}
                                            </InputAdornment>,
                                        endAdornment: <InputAdornment position="end">
                                            {data.typeValue ? '%' : ''}
                                            </InputAdornment>
                                        
                                    }}
                                    inputProps={{
                                        min: 0
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container justifyContent={"center"} alignItems={"center"} textAlign={"center"} marginTop={5}>
                        <Grid item xs={4}>
                            <PrimaryButton
                                handleClick={ handleSubmit }
                                text={"Aplicar"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </ContentSection>
            <ConfirmationModal
                open={showModalAppliedCost}
                onSucces={() => setshowModalAppliedCost(false)}
                isAvailableCancel={false}
                isAvailableAcept={true}
                message={message}
                status={"success"}
            />
        </>
    );
}

export default IndirectCostsTabPanel;