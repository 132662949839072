import axiosServiceV1 from '../../../apiService/axiosService';
import { useMutation, useQuery } from 'react-query';
import { ShowMessage } from '../../../utils/utils';

export const getExchangeRate = () => {
  return axiosServiceV1.get(`Projection/exchange-rate`);
};

export const useGetExchangeRate = () => {
  return useQuery({
      queryKey: ['exhange-rate'],
      queryFn: () => getExchangeRate(),
      enabled: true
  });
};

const getExchangeRateBanxico = (data) => {
  return axiosServiceV1.get(`projection/exchange-rate/banxico?initialDate=${data.startDate}&finalDate=${data.endDate}`);
};

export const useExchangeRateBanxico = (data) => {
  return useQuery({
      queryKey: ['exhange-rate-banxico'],
      queryFn: () => getExchangeRateBanxico(data),
      enabled: false
  });
};

const getSettingsRegion = () => {
  return axiosServiceV1.get(`Projection/settings-region`);
};

export const useSettingsRegion = () => {
  return useQuery({
      queryKey: ['settings-region'],
      queryFn: getSettingsRegion,
      enabled: true
  });
};

const getIndirectCosts = () => {
  return axiosServiceV1.get(`Projection/indirect-costs`);
};

export const useIndirectCosts = () => {
  return useQuery({
      queryKey: ['indirect-costs'],
      queryFn: getIndirectCosts,
      enabled: true
  });
};

const applySettingsRegion = (data) => {
  return axiosServiceV1.post(`Projection/settings-region`, data);
}

export const useApplySettingsRegion = () => {
  return useMutation({
      onError: (_, __, ___) => {
          ShowMessage('Ocurrió un error al intentar aplicar la configuración de región', 'error');
      },
      onSuccess: (data, dataDto, _) => {
          if (data) {
              //ShowMessage('Se aplicó la configuración de región', 'success');
              // console.log(dataDto)
          }

      },

      mutationFn: (data) => applySettingsRegion(data).then(res => res).catch(err => null),
  });
};

const postIndirectCosts = (data) => {
  return axiosServiceV1.post(`Projection/indirect-costs`, data);
}

export const useApplyIndirectCosts = () => {
  return useMutation({
      onError: (_, __, ___) => {
          ShowMessage('Ocurrió un error al guardar los costos indirectos', 'error');
      },
      onSuccess: (data, dataDto, _) => {
          if (data) {
              //ShowMessage('Costos indirectos aplicados correctamente', 'success');
          // console.log(dataDto)
          }
      },
      mutationFn: (data) => postIndirectCosts(data).then(res => res).catch(err => null),
  });
};

const applyConfiguration = (data) => {
    return axiosServiceV1.post(`Projection/exchange-rate`, data);
  }
  
  export const useApplyConfiguration = () => {
    return useMutation({
        onError: (_, __, ___) => {
            ShowMessage('Ocurrió un error al intentar aplicar la configuración', 'error');
        },
        onSuccess: (data, dataDto, _) => {
            if (data) {
                ShowMessage('Se aplico la configuración', 'success');
            }
  
        },
  
        mutationFn: (data) => applyConfiguration(data).then(res => res).catch(err => null),
    });
  };
