import { toast } from 'react-hot-toast';
import { Alert } from '@mui/material';

export const buildUrlAndClean = (routerOne, routerTwo) => {
    const firstRoute = routerOne.replace("/", "");
    const secondRoute = routerTwo.replace("/", "");
    return `/${firstRoute}/${secondRoute}`;
};

export const ShowMessage = (message, type) => {
    toast.custom(() => {
        return (
            <Alert
                elevation={6}
                variant="filled"
                severity={type}
            >
                {message}
            </Alert>)
    }, { duration: 5000 })
}

export const parseQueryParams = data => {
  const queryString = new URLSearchParams(data).toString()
  return queryString
}

export let DefaultValidations

;(function(DefaultValidations) {
  DefaultValidations[(DefaultValidations["ALPHANUMERIC"] = 0)] = "ALPHANUMERIC"
  DefaultValidations[(DefaultValidations["POSTALCODE"] = 1)] = "POSTALCODE"
  DefaultValidations[(DefaultValidations["NUMERIC"] = 2)] = "NUMERIC"
  DefaultValidations[(DefaultValidations["NUMERICOREMPTY"] = 3)] =
    "NUMERICOREMPTY"
  DefaultValidations[
    (DefaultValidations["ALPHANUMERICANDSPECIALCHARACTERS"] = 4)
  ] = "ALPHANUMERICANDSPECIALCHARACTERS"
  DefaultValidations[
    (DefaultValidations["NAMEVALIDATIONANDSPECIALCHARACTERS"] = 5)
  ] = "NAMEVALIDATIONANDSPECIALCHARACTERS"
})(DefaultValidations || (DefaultValidations = {}))

export const NumericValidation = value => {
  var patt = /^[\d\. ]*$/ // /^[ A-Za-z0-9_.()-\/]*$/;// new RegExp("^[ A-Za-z0-9_.()-\/]$");
  if (!RegExp(patt).test(value)) {
    return {
      isValid: false,
      message: "Solo se permiten caracteres numéricos"
    }
  }
  return {
    isValid: true
  }
}

export const NumericOrEmptyValidation = value => {
  var patt = /^[\d\. ]*$/
  if (value && value.length > 0 && !RegExp(patt).test(value)) {
    return {
      isValid: false,
      message: "Solo se permiten caracteres numéricos"
    }
  }
  return {
    isValid: true
  }
}

export const AlphanumericValidation = value => {
  var patt = /[A-Za-z0-9_\-(). ]$/ // /^[ A-Za-z0-9_.()-\/]*$/;// new RegExp("^[ A-Za-z0-9_.()-\/]$");
  if (!RegExp(patt).test(value)) {
    return {
      isValid: false,
      message: "Solo se permiten caracteres alfanuméricos y ()_-"
    }
  }
  return {
    isValid: true
  }
}

//Expresion regular que acepta Alfanumericos y ()_-ALPHANUMERICANDSPECIALCHARACTERS
export const AlphaNumericAndSpecialCharactersValidation = value => {
  var patt = /^[\w .,()"#&\/-]*$/
  if (!RegExp(patt).test(value.trim())) {
    return {
      isValid: false,
      message: 'Solo se permiten caracteres alfanuméricos y .,_-()"#&/'
    }
  }
  return {
    isValid: true
  }
}

//Expresion regular que acepta Alfanumericos y ()_-ALPHANUMERICANDSPECIALCHARACTERS con acento
export const NameValidation = value => {
  var patt = /^[a-záÁéÉìÌíÍóÓúÚñÑA-Z0-9 _.,()"#&/-]+(\s+[a-záÁéÉìÌíÍóÓúÚñÑA-Z0-9 _.,()"#&/-]+)*$/
  if (!RegExp(patt).test(value.trim())) {
    return {
      isValid: false,
      message: 'Solo se permiten caracteres alfanuméricos y .,_-()"#&/'
    }
  }
  return {
    isValid: true
  }
}

//Expresion regular que elimina espacio al inicio y final de un string
export const WhiteSpaceValidation = value => {
  var patt = /^\s+|\s+$/gm
  return value.replace(patt, "")
}

export const PostalCodeValidation = value => {
  var patt = new RegExp("^[0-9]{5}$")
  if (!patt.test(value)) {
    return {
      isValid: false,
      message: "No es un código postal válido"
    }
  }
  return {
    isValid: true
  }
}

export const IntegerAndDecimalValidation = (value, countInterger, countDecimal) => {
  const patt = new RegExp(`^(\\d{1,${countInterger}})(\\.\\d{1,${countDecimal}})?$`, 'gm');
  if (!patt.test(value)) {
    return {
      isValid: false,
      message: "Solo se permiten a lo máximo 10 enteros y máximo 4 decimales"
    }
  }
  return {
    isValid: true
  }
}

export const currencyFormatter = ({ style, currency, value, minFractionDigits}) => {
  const formatter = new Intl.NumberFormat('es-MX', {
    style: style,
    minimumFractionDigits: minFractionDigits,
    currency
  })
  return formatter.format(value)
}

export const currencyFormatterwhitinStyle = ({ currency, value, minFractionDigits}) => {
  const formatter = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: minFractionDigits,
    currency
  })
  return formatter.format(value)
}


//Funcion para formatear un numero a moneda con locales
export const currencyFormatterInputs = ({ value }) => {
  return (value).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
};



export const NumberFormat = (number, local) => {
  return (number !== "") ? parseFloat(number).toLocaleString(local) : ""
}

export const COMPONENTS = {
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3,
  CONFIRM: 4,
  SUCCESS: 5,
  WARNING:6,
  ERROR:7
};