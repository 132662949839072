import { createContext, useReducer, useRef, useState } from "react";
// import { getCriteria } from "../../../helpers/calculation";
import { calculateInitialVariables } from "../calculationsUtils";

const CalculationContext = createContext()

const initialState = {
  electricityPay: null,
  electricityPayDetail: null,
  isSinglePeriod: false,
  electricityPayDetailAux: [],
  pricePayAux: [],
  listBatteriesWithWarranties: [],
  battery: [],
  baterryCost: [],
  exchangeRate: 0,
  receiptCalculations: [],
  batteryInitialCalculation: {
    regionPeak: 0,
    indirectCosts: [],
  },
  baseBatteryCalculationByQuotation: [],
  priceEnergy: null,
  electiricityTariff: null,
  isDelete: false,
  auxQuation: 0,
  utilityPercent: 0,
  hasLowVoltage: false,
}


const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ELECTRICITY_PAY':
      return {
        ...state,
        electricityPay: action.payload,
      }
    case 'SET_ELECTRICITY_PAY_DETAIL':
      return {
        ...state,
        electricityPayDetail: action.payload,
        utilityPercent: action.payload[0].details[0].receiptCalculation[0]?.utility || 0,
      }
    case 'SET_HAS_LOW_VOLTAGE':
      return {
        ...state,
        hasLowVoltage: action.payload,
      }
    case 'SET_IS_SINGLE_PERIOD':
      return {
        ...state,
        isSinglePeriod: action.payload,
      }
    case 'SET_PRICE_ENERGY':
      return {
        ...state,
        priceEnergy: action.payload.priceEnergy,
        electiricityTariff: action.payload.electricityBillTariff
      }
    case 'SET_BATTERIES_WARRANTIES':
      return {
        ...state,
        listBatteriesWithWarranties: action.payload.map((value) => ({
          value: value,
          label: `${value.brand} - ${value.model} - ${value.warranty}`,
        }))
      }
    case 'SET_AUX_QUOTATION':
      return {
        ...state,
        auxQuation: action.payload
      }
    case 'SET_BATTERY':
      const batteries = state.battery;
      if (batteries.length > 0) {
        let battery = state.battery[action.payload.index];
        battery = {
          ...battery,
          ...action.payload.battery
        };
        batteries[action.payload.index] = battery;
      } else {
        batteries.push(action.payload.battery);
      }
      return {
        ...state,
        battery: batteries,
        idBatteryWarranty: `${batteries[action.payload.index]?.warranty}-${batteries[action.payload.index]?.batteryId}`
      }
    case 'SET_BATTERY_COST':
      // Crear una copia nueva del array para evitar mutaciones directas
      const batteriesCost = state.baterryCost ? [...state.baterryCost] : [];
      const batteryAux = state.battery
      if (batteriesCost.length > 0 && batteryAux.length > 0) {
        let battery = batteryAux[action.payload.index];
        const auxCostKW = action.payload.batteryCost.listWarranty.find(x => x.id === battery.warrantyId);
        const costKw = auxCostKW?.priceUser || 0;
        batteriesCost[action.payload.index] = costKw;
      } else if (batteryAux.length > 0) {
        const auxCostKW = action.payload.batteryCost.listWarranty.find(x => x.id === batteryAux[0].warrantyId);
        const costKw = auxCostKW?.priceUser || 0;
        batteriesCost.push(costKw);
      }
      return {
        ...state,
        baterryCost: batteriesCost
      }
    case 'SET_EXCHANGE_RATE':
      return {
        ...state,
        exchangeRate: action.payload
      }

    case 'SET_REGION_PEAK':
      return {
        ...state,
        batteryInitialCalculation: {
          ...state.batteryInitialCalculation,
          regionPeak: action.payload
        }
      }
    case 'SET_INDIRECT_COSTS':
      return {
        ...state,
        batteryInitialCalculation: {
          ...state.batteryInitialCalculation,
          indirectCosts: action.payload
        }
      }
    case 'RESET_BATTERY':
      return {
        ...state,
        battery: [],
        batteriesCost: [],
        listBatteriesWithWarranties: []
      }
    case 'SET_PRICE_PAY_AUX':
      return {
        ...state,
        pricePayAux: action.payload
      }
    case 'SET_AUX_ELECTRICITY_PAY_DETAIL':
      return {
        ...state,
        electricityPayDetailAux: action.payload,
        baseBatteryCalculationByQuotation: action.payload.map((periods) => {
          return periods.map((value) => {

            return calculateInitialVariables(value, state.batteryInitialCalculation, state.isSinglePeriod, state.priceEnergy, state.electricityPay, state.utilityPercent);

          })
        })
      }

    case 'DELETE_AUX_ELECTRICITY_PAY_DETAIL':
      return {
        ...state,
        electricityPayDetailAux: state.electricityPayDetailAux.filter((_value, index) => index !== action.payload),
        pricePayAux: state.pricePayAux.filter((_value, index) => index !== action.payload + 1),
        battery: state.battery.filter((_value, index) => index !== action.payload),
        baterryCost: state.baterryCost.filter((_value, index) => index !== action.payload),
        auxQuation: 0,
        isDelete: true,
      }
    case 'DELETE_CHANGE_STATE':
      return {
        ...state,
        isDelete: false,
      }
    default:
      return state
  }
}

const CalculationProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isChangeBattery, setIsChangeBattery] = useState(false);
  const [isChangeData, setIsChangeData] = useState(false);
  const skipEffectRef = useRef(false);

  return (
    <CalculationContext.Provider value={{ state, dispatch, isChangeBattery, setIsChangeBattery, skipEffectRef, isChangeData, setIsChangeData }}>
      {props.children}
    </CalculationContext.Provider>
  )
}

export { CalculationContext, CalculationProvider }