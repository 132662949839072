import {
    Grid,
    Typography,
    Button,
    Box,
    Dialog
} from "@mui/material";
import { InvoicePeriod } from "./InvoicePeriod";
import { Information } from "./Information";
import { useContext, useEffect, useState } from "react";
import CustomTemplate from "../../../components/CustomTemplate/CustomTemplate";
import { ViewSection } from "./ViewSection";
import { CustomModal } from "../../../components/Modal/CustomModal";
import { Uploader } from "../../../components/Uploader/Uploader";
import { DividerTitle, StyledNameContent, StyledTextField, StyledImagenPreview } from "./styled.components";
import { PricePayContainer } from "./PricePay/PricePayContainer";
import { useCreateProcessElectricityBill, useDownloadElectricityBillFile, useGetBatteriesWithWarranties, useGetElectricityBill, useGetElectricityBillTariff, useGetExchangeRate, useGetRegionPeak, useSaveProcessElectricityBill } from "../api/CalculationsApi";
import { ShowMessage } from "../../../utils/utils";
import { DetailPayContainer } from "./DetailPay/DetailPayContainer";
import { useGetElectricityDetail } from "../api/CalculationsApi";
import { Loader } from "../../../components/Base/Loader";
import { CustomButton } from "../../../components/Modal/Styled.components";
import PreviewReceipt from "../../../assets/images/preview.png";
import { useNavigate, useParams } from "react-router-dom";
import { StyledDelete } from "./DetailPay/Styled.components";
import { Warning } from "@mui/icons-material";
import { CalculationContext } from "../context/CalculationContext";
import { useIndirectCosts } from "../../ExchangeRate/api/ExchangeRateApi";

export const NewCalculations = ({ onBack }) => {
    const { dispatch, state: { pricePayAux, battery, baterryCost } } = useContext(CalculationContext);
    const MAXLENGHT = 45;
    const { id } = useParams();
    const history = useNavigate();
    const [showreceiptmodal, setShowReceiptModal] = useState(false);
    const [isDoublePeriod, setIsDoublePeriod] = useState(1);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    //const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [savedResultProcess, setSavedResultProcess] = useState();
    const [dataInvoice, setDataInvoice] = useState([]);
    const [updateData, setUpdateData] = useState(null);
    const [dataInvoicePeriod, setDataInvoicePeriod] = useState([]);
    const [deletePrice, setDeletePrice] = useState(null);
    const [showsavemodal, setShowSaveModal] = useState(false);
    const [nameReceipt, setNameReceipt] = useState("");
    const [billedDays, setBilledDays] = useState(0);
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);

    const [errorName, setErrorName] = useState({
        error: false,
        message: ""
    });

    const isVisibility = !!id;

    const columnsInvoicePeriod = [
        { title: "Fecha inicial" },
        { title: "Fecha final" },
        { title: "Dias de facturacion" }
    ];

    const information = savedResultProcess ? [
        { title: "No de recibo", body: (<Typography variant="subtitle2">{savedResultProcess?.serviceNumber}</Typography>) },
        { title: "Tipo", body: (<Typography variant="subtitle2">{savedResultProcess?.idReceiptType === 1 ? "Normal" : savedResultProcess.idReceiptType === 2 ? "Doble Periodo" : ""}</Typography>) },
        { title: "Razón social", body: (<Typography variant="subtitle2">{savedResultProcess?.receiptHolder}</Typography>) },
        { title: "Tarifa", body: (<Typography variant="subtitle2">{savedResultProcess?.tariffName}</Typography>) },
        { title: "Código postgal", body: (<Typography variant="subtitle2">{savedResultProcess?.zipCode}</Typography>) },
        { title: "Periodo facturado", body: (<InvoicePeriod columns={columnsInvoicePeriod} data={dataInvoicePeriod} />) }
    ] : [];

    const { data: batteriesWarranties } = useGetBatteriesWithWarranties(savedResultProcess);
    const { data: exchangeRate } = useGetExchangeRate(savedResultProcess);
    const { data: indirectCosts } = useIndirectCosts();
    const newProcessElectricityBill = useCreateProcessElectricityBill();
    const electricityDetail = useGetElectricityDetail(id ?? savedResultProcess?.billId);
    const saveProcessElectricityBill = useSaveProcessElectricityBill();
    const electricityBill = useGetElectricityBill(id);
    const electricityBillDownload = useDownloadElectricityBillFile(id ?? savedResultProcess?.billId);
    const { data: electricityTariff } = useGetElectricityBillTariff(id ?? savedResultProcess?.billId);
    const { data: regionPeak } = useGetRegionPeak(savedResultProcess?.zipCode);

    useEffect(() => {
        if (batteriesWarranties) {
            dispatch({ type: 'SET_BATTERIES_WARRANTIES', payload: batteriesWarranties });
        }
    }, [batteriesWarranties, dispatch]);

    useEffect(() => {

        if (electricityDetail.isSuccess) {
            dispatch({ type: 'SET_ELECTRICITY_PAY_DETAIL', payload: electricityDetail.data });
            const hasLowVoltage = electricityDetail.data[0].summaryAmount.some(item => item.lowVoltage2p !== 0);
            dispatch({ type: 'SET_HAS_LOW_VOLTAGE', payload: hasLowVoltage });
        }

    }, [electricityDetail.data, electricityDetail.isSuccess, dispatch])

    useEffect(() => {
        if (exchangeRate) {
            dispatch({ type: 'SET_EXCHANGE_RATE', payload: exchangeRate.exchangeRate });
        }
    }, [exchangeRate, dispatch])

    useEffect(() => {
        if (indirectCosts) {
            dispatch({ type: 'SET_INDIRECT_COSTS', payload: indirectCosts });
        }
    }, [indirectCosts, dispatch])

    useEffect(() => {
        if (regionPeak) {
            dispatch({ type: 'SET_REGION_PEAK', payload: regionPeak });
        }
    }, [regionPeak, dispatch])

    useEffect(() => {
        if (Array.isArray(electricityTariff)) {
            dispatch({
                type: 'SET_PRICE_ENERGY', payload: {
                    electricityBillTariff: electricityTariff,
                    priceEnergy: {
                        supplyValue: electricityTariff.find(item => item.conceptName === "Suministro")?.value || 0,
                        distributionValue: electricityTariff.find(item => item.conceptName === "Distribucion (KW)")?.value || 0,
                        transmitionValue: electricityTariff.find(item => item.conceptName === "Transmision (KWh)")?.value || 0,
                        cenaeOperationValue: electricityTariff.find(item => item.conceptName === "CENACE (KWh)")?.value || 0,
                        generationBValue: electricityTariff.find(item => item.conceptName === "Generacion B(KWh)")?.value || 0,
                        generationIValue: electricityTariff.find(item => item.conceptName === "Generacion I (KWh)")?.value || 0,
                        generationPValue: electricityTariff.find(item => item.conceptName === "Generacion P (KWh)")?.value || 0,
                        capacityValue: electricityTariff.find(item => item.conceptName === "Capacidad (KW)")?.value || 0,
                        memValue: electricityTariff.find(item => item.conceptName === "SCnMEM (KWh)")?.value || 0,
                    }
                }
            });
        }
    }, [electricityTariff, dispatch])

    useEffect(() => {
        return () => {
            dispatch({
                type: "RESET_BATTERY"
            });
        }

    }, [dispatch])

    const validateFiles = (files) => {
        files = files || [];
        let validationErrors = {};
        const fileNames = files.map(file => file.name);
        const duplicateFiles = fileNames.filter((name, index) => fileNames.indexOf(name) !== index);

        if (duplicateFiles.length > 0) {
            validationErrors.duplicates = "No se permite subir el mismo archivo más de una vez";
        }

        const pdfFiles = files.filter(file => file.type === 'application/pdf');
        const imageFiles = files.filter(file => file.type.startsWith('image/'));

        if (files.length > 0 && (pdfFiles.length > 0 && imageFiles.length > 0)) {
            validationErrors.mixed = "No se permite mezclar tipos de archivos";
        } else {
            if (pdfFiles.length > 1) {
                validationErrors.pdf = "Solo se permite subir 1 archivo PDF";
            }

            if (imageFiles.length > 0 && imageFiles.length !== 2) {
                validationErrors.images = "Debe subir exactamente 2 imágenes";
            }
        }

        if (files.length === 0) {
            validationErrors.files = "Debe subir al menos un archivo";
        }

        return validationErrors;
    };

    const handleSave = async () => {
        const validationErrors = validateFiles(uploadedFiles);
        if (Object.keys(validationErrors).length > 0) {
            ShowMessage(validationErrors.mixed || validationErrors.pdf || validationErrors.images || validationErrors.files || validationErrors.duplicates, 'error');
            return;
        }
        setIsLoading(true);

        const formData = new FormData();
        uploadedFiles.forEach(obj => {
            if (obj.name) {
                formData.append('files', obj);
            }
        });

        try {
            const result = await newProcessElectricityBill.mutateAsync(formData);
            
            if (typeof result === 'string') {
                const response = JSON.parse(result);
                if (response.status === 500) {
                    setIsLoading(false);
                    return;
                }
            }

            // Si result tiene valor, se asume éxito y se procede a cerrar el modal
            if (result) {
                dispatch({ type: 'SET_ELECTRICITY_PAY', payload: result });
                setSavedResultProcess(result);
                setShowReceiptModal(false);
            }
            // En cualquier caso, se detiene el indicador de carga
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleFilesUploaded = (files) => {
        setUploadedFiles(files);
    };

    const handleAlphaNumericInput = (value) => {
        var regex = /[A-ZáÁéÉìÌíÍóÓúÚñÑa-z0-9_\-(). ]+$/;
        if (!RegExp(regex).test(value)) {
            return false
        }
        return true
    };

    const handleSaveReceipt = async () => {
        if (nameReceipt.length > 0 && handleAlphaNumericInput(nameReceipt)) {
            setErrorName({ error: false, message: "" });
        } else if (nameReceipt.length === 0) {
            setErrorName({ error: true, message: "Nombre requerido" });
            return;
        } else if (!handleAlphaNumericInput(nameReceipt)) {
            setErrorName({ error: true, message: "Solo se permiten caracteres numéricos" });
            return;
        }

        setIsLoading(true);

        // const dataReceiptObj = dataInvoice;// cotizacionObj2;
        const dataReceiptObj = pricePayAux.slice(1);
        const fillDataReceipt = (cotizacionObj) => {
            const details = cotizacionObj.flatMap((cotizacion, index) =>
                cotizacion.details.map(detail => {
                    const criteria = detail.criteria.map(crit => ({
                        criteriaId: crit.consumptionId ?? 0,
                        capacityA: crit.capacityA,
                        capacityB: crit.capacityB,
                        distributionA: crit.distributionA,
                        distributionB: crit.distributionB
                    }))[0];


                    const consumption = detail.consumption.map(cons => ({
                        consumptionId: cons.consumptionId ?? 0,
                        totalConsumption: cons.totalConsumption,
                        monthlyMinimum: cons.monthlyMinimum,
                        fpBonus: cons.fpBonus,
                        fpPenalty: cons.fpPenalty,
                        averagePrice: cons.averagePrice,
                        loadFactor: cons.loadFactor
                    }))[0];

                    const foundSummaryAmount = cotizacion.data.find(data => data.periodTypeId === detail.periodTypeId);
                    const summaryAmount = foundSummaryAmount
                        ? {
                            summaryAmountId: foundSummaryAmount.amountBreakdownId ?? 0,
                            fixedCharge: foundSummaryAmount.fixedCharge,
                            energy: foundSummaryAmount.energy,
                            lowVoltage2p: foundSummaryAmount.lowVoltage2p,
                            powerFactor: foundSummaryAmount.powerFactor,
                            subtotal: foundSummaryAmount.subtotal,
                            tax: foundSummaryAmount.tax,
                            billingPeriod: foundSummaryAmount.billingPeriod,
                            lightingFee: foundSummaryAmount.lightingFee,
                            totalAmount: foundSummaryAmount.totalAmount
                        }
                        : {};
                    const receiptCalculation = detail.receiptCalculation.map(calc => ({
                        receiptCalculationId: calc.receiptCalculationId ?? 0,
                        baseConsumptionKwh: calc.baseConsumptionKwh,
                        intermediateConsumptionKwh: calc.intermediateConsumptionKwh,
                        peakConsumptionKwh: calc.peakConsumptionKwh,
                        demandBaseKw: calc.demandBaseKw,
                        demandIntermediateKw: calc.demandIntermediateKw,
                        demandPeakKw: calc.demandPeakKw,
                        requiredInvestmentUSD: calc.requiredInvestmentUSD,
                        requiredInvestmentMXN: calc.requiredInvestmentMXN,
                        maintenanceUSD: calc.maintenanceUSD,
                        maintenanceAnnualUSD: calc.maintenanceAnnualUSD,
                        maintenanceAnnualMXN: calc.maintenanceAnnualMXN,
                        installationCost: calc.installationCost,
                        installationCostPercent: calc.installationCostPercent,
                        civilWorkCost: calc.civilWorkCost,
                        civilWorkCostPercent: calc.civilWorkCostPercent,
                        otherExpenses: calc.otherExpenses,
                        otherExpensesPercent: calc.otherExpensesPercent,
                        maintenance: calc.maintenance,
                        maintenancePercent: calc.maintenancePercent,
                        operationCost: calc.operationCost,
                        operationCostPercent: calc.operationCostPercent,
                        batteryCost: baterryCost[index],
                        requiredInvestmentSAEUSD: calc.requiredInvestmentSAEUSD,
                        requiredInvestmentSAEMXN: calc.requiredInvestmentSAEMXN,
                        totalCapacityAmount: calc.totalCapacityAmount,
                        roiUSD: calc.roiUSD,
                        roiMXN: calc.roiMXN,
                        monthllyRoi: calc.monthllyRoi,
                        annualRoi: calc.annualRoi,
                        utilityMxn: calc.utilityMxn,
                        utility: calc.utility,
                        leasingPrice: calc.leasingPrice,
                        initialExpensePercent: calc.initialExpensePercent,
                        initialExpense: calc.initialExpense,
                        periodMonths: calc.periodMonths,
                        residualValuePercent: calc.residualValuePercent,
                        residualValue: calc.residualValue,
                        initialPaymentUSD: calc.initialPaymentUSD,
                        initialPaymentMXN: calc.initialPaymentMXN,
                        annualInterestRate: calc.annualInterestRate,
                        monthlyPaymentUSD: calc.monthlyPaymentUSD,
                        monthlyPaymentMXN: calc.monthlyPaymentMXN,
                        finalPaymentUSD: calc.finalPaymentUSD,
                        finalPaymentMXN: calc.finalPaymentMXN,
                        total: calc.total,
                        netProfit: calc.netProfit,
                        utilityMxnPercent: calc.utilityMxnPercent,
                        warrantyId: battery[index].warrantyId,
                    }))[0];

                    const listEnergyCost = detail.listEnergyCost
                        ? detail.listEnergyCost.map(cost => {
                            const baseCost = {
                                energyCostId: cost.energyCostId,
                                conceptId: cost.conceptId,
                                price: cost.value,
                                totalAmount: cost.totalAmount
                            };
                            if (cost.conceptId === 1) {
                                baseCost.cost = cost.cost;
                            } else if (cost.conceptId === 2 || cost.conceptId === 8) {
                                baseCost.costKw = cost.costPerKw;
                            } else if ([3, 4, 5, 6, 7, 9].includes(cost.conceptId)) {
                                baseCost.costKwh = cost.costPerKwh;
                            }
                            return baseCost;
                        })
                        : [];

                    return {
                        quotationName: cotizacion.quotationName,
                        receiptDetailId: detail.receiptDetailId,
                        tariffTypeId: detail.tariffTypeId,
                        periodTypeId: detail.periodTypeId,
                        calculationTypeId: detail.calculationTypeId,
                        startDate: detail.startDate,
                        endDate: detail.endDate,
                        billedDays: detail.billedDays,
                        baseDemand: detail.baseDemand,
                        intermediateDemand: detail.intermediateDemand,
                        peakDemand: detail.peakDemand,
                        baseConsumption: detail.baseConsumption,
                        intermediateConsumption: detail.intermediateConsumption,
                        peakConsumption: detail.peakConsumption,
                        reactivePower: detail.reactivePower,
                        measurement: detail.measurement,
                        kwmaxAm: detail.kwmaxAm,
                        powerFactor: detail.powerFactor,
                        quotationId: cotizacion.idCotizacion,
                        criteria: criteria,
                        summaryAmount: summaryAmount,
                        consumption: consumption,
                        listEnergyCost: listEnergyCost,
                        receiptCalculation: receiptCalculation
                    };
                })
            );

            return {
                billId: savedResultProcess?.billId,
                calculationName: nameReceipt,
                details: details
            };
        };


        const filledDataReceipts = fillDataReceipt(dataReceiptObj);

        try {
            const result = await saveProcessElectricityBill.mutateAsync(filledDataReceipts);
            if (result) {
                //ShowMessage('Se guardo correctamente', 'success');
                setShowSaveModal(false);
                history(-1);
            }
        } catch (error) {
            console.log("error ", error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        if (id) {
            electricityDetail.refetch();
        }
        if (savedResultProcess && !id) {
            setIsDoublePeriod(savedResultProcess.idReceiptType);
            setDataInvoicePeriod(savedResultProcess.periods);
            setBilledDays(savedResultProcess.billedDays);
            dispatch({ type: 'SET_IS_SINGLE_PERIOD', payload: savedResultProcess.idReceiptType === 1 });
            electricityDetail.refetch();
        }
    }, [savedResultProcess, id]);

    useEffect(() => {
        if (electricityDetail.isSuccess && electricityDetail.data && id) {
            setBilledDays(electricityDetail.data[0]?.details[0]?.billedDays);
            electricityBill.refetch();
        }
    }, [electricityDetail.isSuccess]);

    useEffect(() => {
        if (electricityBill.isSuccess && electricityBill.data && id) {
            setSavedResultProcess(electricityBill.data);
            setDataInvoicePeriod(electricityBill.data.periods);
            setIsDoublePeriod(electricityBill.idReceiptType);
            dispatch({ type: 'SET_IS_SINGLE_PERIOD', payload: electricityBill.idReceiptType === 1 });
        }
    }, [electricityBill.isSuccess]);

    const handleAddInvoice = (params) => {
        const updatedParams = params.map(param => ({
            ...param,
            listEnergyCost: param.listEnergyCost || []
        }));

        // console.log("updatedParams", updatedParams);

        setDataInvoice(updatedParams);
    };

    const handleUpdateData = (updatedData) => {
        updatedData.listEnergyCost = updatedData.listEnergyCost || [];
        setUpdateData(updatedData);
    };

    const handleConfirmCancel = () => {
        setShowConfirmCancelModal(false);
        history(`/energy/calculos`);
    };

    const handleButtonClick = () => {
        if (savedResultProcess) {
            // Llamar a la función de descarga si el texto del botón es "Descargar Recibo"
            electricityBillDownload.refetch();
        } else {
            // Alternativamente, si el texto es "Cargar Recibo", mostrar el modal
            setShowReceiptModal(true);
        }
    };

    return (
        <>
            <CustomTemplate title={(id) ? "Detalle Cálculo" : "Nuevo Cálculo"} hasButtonBack onNavigateBack={onBack}>
                <Grid container>
                    <ViewSection
                        title={(<Typography variant="h6">Recibo CFE</Typography>)}
                        custombtn={
                            <CustomButton
                                variant="outlined"
                                onClick={handleButtonClick}
                            >
                                {(id || savedResultProcess) ? "Descargar Recibo" : "Cargar Recibo"}
                            </CustomButton>
                        }
                        divider={(<DividerTitle />)}
                    >
                        <Information data={information} />
                    </ViewSection>
                    {electricityDetail.isSuccess && electricityTariff ? <PricePayContainer data={electricityDetail.data} setAddInvoice={handleAddInvoice} deleteprice={deletePrice} setDeletePrice={setDeletePrice} updateData={updateData} isVisibility={isVisibility} /> : null}
                    {electricityDetail.isSuccess && electricityTariff ? <DetailPayContainer isDoublePeriod={isDoublePeriod} data={dataInvoice} setDeletePrice={(index) => setDeletePrice(index)} billedDays={billedDays} setUpdateData={handleUpdateData} isVisibility={isVisibility} /> : null}

                    {electricityDetail.isSuccess && !isVisibility ?
                        <Grid item container justifyContent={"center"} alignItems={"center"} columnSpacing={5} marginTop={5} marginBottom={7}>
                            <Grid item xs={2} textAlign={"right"}>
                                <Button variant="outlined" color="error" onClick={() => { setShowConfirmCancelModal(true) }} fullWidth>
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <CustomButton
                                    fullWidth
                                    variant="contained"
                                    onClick={() => setShowSaveModal(true)}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <Loader size={'30px'} loading={isLoading} /> : "Guardar"}
                                </CustomButton>
                            </Grid>
                        </Grid> : null}
                </Grid>
            </CustomTemplate >
            {showreceiptmodal && <CustomModal
                title={"Cargar recibo"}
                open={showreceiptmodal}
                isLoading={isLoading}
                labelAccept={"Guardar"}
                close={() => setShowReceiptModal(false)}
                onSuccess={() => handleSave()}
                maxWidth="sm"
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Uploader
                            onFilesUploaded={handleFilesUploaded}
                            titlehelp={"Sube el documento escaneado de ambos lados en formato PDF o imagen (JPG) asegurandote de que sea lo mas legible posible."}
                            showHelpIcon={true}
                            acceptedFileTypes={["application/pdf", "image/jpeg"]}
                            maxFiles={2}
                        >
                            <StyledImagenPreview src={PreviewReceipt} alt="preview" />
                        </Uploader>
                    </Grid>
                </Grid>
            </CustomModal>}
            {showsavemodal && <CustomModal
                title={"Guardar calculo"}
                open={showsavemodal}
                isLoading={isLoading}
                labelAccept={"Guardar"}
                close={() => setShowSaveModal(false)}
                onSuccess={handleSaveReceipt}
                maxWidth="sm"
            >
                <StyledNameContent container>
                    <Grid item xs={12}>
                        <Box mt={2}>
                            <Typography variant="subtitle2" gutterBottom>Ingresa nombre</Typography>
                            <StyledTextField
                                fullWidth
                                value={nameReceipt}
                                onChange={(e) => {
                                    setNameReceipt(e.target.value);
                                }}
                                inputProps={{
                                    maxLength: MAXLENGHT,
                                }}
                                error={errorName.error}
                            />
                        </Box>
                        {errorName.error && (
                            <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                                {errorName.message}
                            </Typography>
                        )}
                    </Grid>
                </StyledNameContent>
            </CustomModal>}

            {showConfirmCancelModal && <Dialog open={showConfirmCancelModal}>
                <StyledDelete container justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                    <Grid item xs={12} marginBottom={3}><Warning /></Grid>
                    <Grid item xs={12} marginBottom={2}>¿Estás seguro de que deseas cancelar el cálculo del recibo?</Grid>
                    <Grid item xs={12} container columnSpacing={2} textAlign={"center"} justifyContent={"center"} alignItems={"center"} marginTop={3} marginBottom={3}>
                        <Grid item xs={4}>
                            <Button variant="outlined" color="error" onClick={() => setShowConfirmCancelModal(false)} fullWidth>
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <CustomButton
                                fullWidth
                                variant="contained"
                                onClick={(e) => handleConfirmCancel()}
                                disabled={isLoading}
                            >
                                {isLoading ? <Loader size={'30px'} loading={isLoading} /> : "Aceptar"}
                            </CustomButton>
                        </Grid>
                    </Grid>
                </StyledDelete>
            </Dialog>}
        </>
    )
}