import { memo, useRef } from "react";
import { Box, Typography, InputAdornment } from "@mui/material";
import { InputStyles, TextReadOnlyStyles, StyledTextField, StyledTextFieldReadOnly } from "./Input.Styled";
import { currencyFormatterInputs } from "../../../utils/utils";

export const InputText = (props) => {
    return (
        <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>{props.title}</Typography>
            <InputStyles {...props} fullWidth={props.notFull ? false : true} size='normal' />
        </Box>
    );
}

export const InputTextReadOnly = (props) => {
    return (
        <Box className={props?.className ?? ""} mt={2}>
            <Typography variant="subtitle2" gutterBottom>{props.title}</Typography>
            <TextReadOnlyStyles>{props.value}</TextReadOnlyStyles>
        </Box>
    );
}

/**
 * InputTextCurrency es un componente de entrada de texto que formatea el valor como moneda.
 * @param {object} props Las propiedades del componente.
 * @param {number} props.value El valor numérico.
 * @param {string} props.name El nombre del campo.
 * @param {boolean} props.readOnly Indica si el campo es de solo lectura.
 * @param {string} props.currency La moneda a mostrar.
 * @param {function} props.onChange La función que maneja el cambio de valor pasando el nombre del campo y el valor.
 */
export const InputTextCurrency = memo(({ value, name, readOnly, currency, onChange }) => {
    const inputRef = useRef(null);

    // Manejar cambios en el input
    const handleChange = (event) => {
        const rawText = event.target.value.replace(/[^0-9]/g, ""); // Permitir solo números

        let numberValue = parseInt(rawText, 10) || 0; // Convertir a número entero (centavos)

        const formated = numberValue / 100;

        onChange(event.target.name, formated); // Actualizar el valor

        // Posicionar el cursor siempre al final
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.setSelectionRange(event.target.value.length, event.target.value.length);
            }
        }, 0);
    };


    return readOnly ? (
        <StyledTextFieldReadOnly
            inputRef={inputRef}
            fullWidth
            value={currencyFormatterInputs({ value: value ?? 0 })}
            type="text"
            name={name}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>, // Prefijo estático
                endAdornment: <InputAdornment position="end">{currency}</InputAdornment>, // Sufijo estático
                readOnly: true,
            }}
            inputProps={{ inputMode: "numeric" }}
        />
    ) : (
        <StyledTextField
            inputRef={inputRef}
            fullWidth
            value={currencyFormatterInputs({ value: value ?? 0 })}
            type="text"
            name={name}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>, // Prefijo estático
                endAdornment: <InputAdornment position="end">{currency}</InputAdornment>, // Sufijo estático
            }}
            inputProps={{ inputMode: "numeric" }}
            onChange={handleChange}
        />
    );
});


/**
 * InputNumberWithTrailEnd es un componente de entrada de número con un sufijo estático.
 * @param {object} props Las propiedades del componente.
 * @param {number} props.value El valor numérico.
 * @param {string} props.name El nombre del campo.
 * @param {boolean} props.readOnly Indica si el campo es de solo lectura.
 * @param {string} props.unitValue El valor del sufijo.
 * @param {function} props.onChange La función que maneja el cambio de valor pasando el nombre del campo y el valor. 
 */
export const InputNumberWithTrailEnd = memo(({ value, name, readOnly, unitValue, onChange, allowDecimals }) => {

    const handleChange = (event) => {
        let inputValue = event.target.value;
        const currentValue = value?.toString() || "";

        if (allowDecimals) {
            inputValue = inputValue.replace(/[^0-9.]/g, "");
            const decimalCount = (inputValue.match(/\./g) || []).length;

            if (decimalCount > 1) return;

            const parts = inputValue.split(".");
            if (parts.length === 2 && parts[1].length > 2) return;
        } else {
            inputValue = inputValue.replace(/[^0-9]/g, "");
        }

        // ✅ Si el valor actual es "0", reemplazarlo por el nuevo valor ingresado
        const newValue = currentValue === "0" ? inputValue : inputValue;

        onChange && onChange(event.target.name, newValue);
    };

    return readOnly ? (
        <StyledTextFieldReadOnly
            fullWidth
            value={value}
            name={name}
            InputProps={{
                endAdornment: <InputAdornment position="end">{unitValue}</InputAdornment>, // Sufijo estático
                readOnly: true,
            }}
            inputProps={{ inputMode: "decimal" }}
        />

    ) : (
        <StyledTextField
            fullWidth
            value={value}
            name={name}
            InputProps={{
                endAdornment: <InputAdornment position="end">{unitValue}</InputAdornment>, // Sufijo estático
            }}
            inputProps={{ inputMode: "decimal" }}
            onChange={handleChange}
        />
    );
});