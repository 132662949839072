import { useContext, useEffect, useMemo, useState } from "react";
import { CustomModal } from "../../../../components/Modal/CustomModal";
import { Grid, Typography } from "@mui/material";
import { InputTextReadOnly } from "../../../../components/Base/Inputs";
import { PrimaryButtonWithIcon } from "../../../../components/Base/Buttons/Buttons";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { ShowChartOutlined } from "@mui/icons-material";
import { ProjectionsContext } from "../../../../utils/context/projectionsContext";
import { useAnnualProjectionForNode, useGetInvestmentProjection, useMonthlyProjectionForNode } from "../../api/Projectionsapi";
import GenericTable from "../../../../components/DataTable/GenericTable";
import { ContainerPriceNode } from "../TablesPriceNodes/styled-component";
import { TabsGeneric } from "../../../Calculations/components/DetailPay/TabPanel";
import ChartPriceNodes from "../ChartPriceNodes/ChartPriceNodes";
import { currencyFormatter } from "../../../../utils/utils";
import { StyledAccordionDetails, StyledTableWrapper } from "../Projections.Styled";
import Select from "../../../../components/Select/Select";
import { useForm } from "../../../../hooks/useForm";

const columnsMonthly = [
    { id: "number", label: "Día de carga" },
    { id: "price", label: "$/Mwh" },
    { id: "number", label: "Día de descarga" },
    { id: "price", label: "$/Mwh" },
];

const columnsAnnual = [
    { id: "number", label: "Mes" },
    { id: "price", label: "Utilidad" },
];


const NodeActivityLogModal = ({ isOpen, onClose }) => {
    const [view, setView] = useState("table");
    const [tabIndex, setTabIndex] = useState(0);
    const [options, setMonths] = useState([]);
    const [optionsYears, setYears] = useState([]);

    const { selectedNodeDetail, state } = useContext(ProjectionsContext);
    const { nodeId, node, nodeDate } = selectedNodeDetail;
    const { megaWatts, timeDownloadId } = state;

    const month = nodeDate ? new Date(`${nodeDate}T00:00:00`).getMonth() + 1 : null;
    const year = nodeDate ? new Date(nodeDate).getFullYear() : null;

    const [selectedMonth, setSelectedMonth] = useState(month);
    const [pickedYear, setPickedYear] = useState(year);
    const [defaultValue, setDefaultValue] = useState();
    const daysInMonth = new Date(pickedYear, selectedMonth, 0).getDate();

    const { data: monthlyData, refetch: refetchMonthly } = useMonthlyProjectionForNode(nodeId, selectedMonth, pickedYear, megaWatts, timeDownloadId);
    const { data: annualData, refetch: refetchAnnual } = useAnnualProjectionForNode(nodeId, pickedYear, megaWatts, timeDownloadId);

    useEffect(() => {
        if (nodeId && selectedMonth && pickedYear && megaWatts && timeDownloadId) {
            refetchMonthly();
            refetchAnnual();
        }
    }, [nodeId, selectedMonth, pickedYear, megaWatts, timeDownloadId, refetchMonthly, refetchAnnual]);

    const commonDatasetProperties = {
        tension: 0.1,
        pointRadius: 10,
        pointHoverRadius: 15,
    };

    const dataArrays = useMemo(() => {
        if (tabIndex === 0 && Array.isArray(monthlyData)) {
            const loadData = monthlyData.map(({ dayNumber, load }) => ({
                number: dayNumber,
                price: currencyFormatter({ style: "currency", currency: "MXN", value: load, minFractionDigits: 2 })
            }));
            const unloadData = monthlyData.map(({ dayNumber, unload }) => ({
                number: dayNumber,
                price: currencyFormatter({ style: "currency", currency: "MXN", value: unload, minFractionDigits: 2 })
            }));
            return [loadData, unloadData];
        } else if (tabIndex === 1 && Array.isArray(annualData)) {
            return [annualData.map(({ month, utility }) => ({
                number: month,
                price: currencyFormatter({ style: "currency", currency: "MXN", value: utility, minFractionDigits: 2 })
            }))];
        }else{
            const loadData = Array.from({ length: daysInMonth }, (_, index) => ({
                number: index + 1,
                price: 0
            }));
            const unloadData = Array.from({ length: daysInMonth }, (_, index) => ({
                number: index + 1,
                price: 0
            }));
            return [loadData, unloadData];
        }
        return [];
    }, [monthlyData, annualData, tabIndex]);


    const columns = useMemo(() => (tabIndex === 0 ? columnsMonthly : columnsAnnual), [tabIndex]);

    const { setPropertiesValue, handleSubmit, handleChange, data: calculationNode, errors, setErrors } = useForm({
            initialValues: null,
            validations: {
                megaWatts: {
                    required: { message: "Este campo es requerido" },
                    custom: {
                        isValidFn: (value) => {
                            const valueAsString = String(value);
                            return valueAsString.length <= 10;
                        },
                        message: "Solo se permiten un máximo de 10 carácteres"
                    },
                },
                timeDownloadId: {
                    required: { message: "Este campo es requerido" }
                },
    
            },
            onSubmit: async () => {
                investmentProjectionQuery.refetch()
            }
        });
        const investmentProjectionQuery = useGetInvestmentProjection({ megaWatts: calculationNode?.megaWatts, timeDownload: calculationNode?.timeDownloadId });

    const { disabled, handleValues, setSelectNode, setSelectBatterie, reset, setDisabled } = useContext(ProjectionsContext);

    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    useEffect(() => {
        const auxData = [];
        months.map((value, index) => {
            auxData.push({
                value: index + 1,
                label: value
            })
        });
        setMonths(auxData);
    }, []);

    useEffect(() => {
        const getMonth = new Date().getMonth();
        setDefaultValue(getMonth + 1);
    }, []);

    useEffect(() => {
        const years = [
            { label: currentYear.toString(), value: currentYear },
            { label: (currentYear - 1).toString(), value: currentYear - 1 },
            { label: (currentYear - 2).toString(), value: currentYear - 2 }
        ];
        setYears(years);
    }, []);

    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(optionsYears);
    const [monthOptions, setMonthOptions] = useState(options);

    useEffect(() => {
        if (parseInt(selectedYear) === currentYear) {
          setMonthOptions(
            options.filter(
              (option) => parseInt(option.value) <= defaultValue
            )
          );
        } else {
          setMonthOptions(options);
        }
    }, [selectedYear, defaultValue, currentYear]);

    return (
        <CustomModal
            title={"Registro de actividad del nodo"}
            open={isOpen}
            isLoading={false}
            labelAccept={"Aceptar"}
            isDisabledCancel={true}
            close={onClose}
            onSuccess={onClose}
            width={"100%"}
            fullWidth={false}
            maxWidth={"lg"}
        >
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    {<InputTextReadOnly title={"Nodo"} value={node} />}
                </Grid>
                <Grid item xs={4} mt={2}>
                    <Typography variant="subtitle2" gutterBottom>Mes</Typography>
                    <Select
                        options={monthOptions}
                        onChange={(value) => {
                            setSelectedMonth([value]);
                            setPropertiesValue({ timeDownloadId: value })
                            handleValues({ ...state, timeDownloadId: value })
                        }}
                        searchable={true}
                        defaultValue={defaultValue}
                        disabled={tabIndex == 1}
                    />
                </Grid>
                <Grid item xs={4} mt={2}>
                    <Typography variant="subtitle2" gutterBottom>Año</Typography>
                    <Select
                        options={optionsYears}
                        onChange={(value) => {
                            setSelectedYear(value)
                            setPickedYear(value)
                        }}
                        searchable={true}
                        defaultValue={currentYear}
                    />
                </Grid>

                <Grid container item xs={12} gap={2}>
                    <Grid item xs={4}>
                        <PrimaryButtonWithIcon
                            text={view === "table" ? "Ver gráfica" : "Ver tabla"}
                            bgColor={"#34c85a"}
                            handleClick={() => setView(view === "table" ? "chart" : "table")}
                            icon={
                                view === "table" ? (
                                    <ShowChartOutlined />
                                ) : (
                                    <TableChartOutlinedIcon />
                                )
                            }
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <StyledAccordionDetails>
                                {view === "table" ? (
                                    <TabsGeneric
                                        tabs={["Mensual", "Anual"]}
                                        tabvalue={tabIndex}
                                        setUpdateTab={setTabIndex}
                                        components={[
                                            <StyledTableWrapper>
                                                <ContainerPriceNode key="monthly" $columncount={dataArrays.length}>
                                                    {dataArrays.map((data, index) => (
                                                        <GenericTable
                                                            key={index}
                                                            columns={columns.slice(index * 2, index * 2 + 2)}
                                                            data={data}
                                                            adjustColumn={["node", "price"]}
                                                        />
                                                    ))}
                                                </ContainerPriceNode>
                                            </StyledTableWrapper>,
                                            <StyledTableWrapper>
                                                <ContainerPriceNode key="annual" $columncount={1}>
                                                    <GenericTable
                                                        columns={columns}
                                                        data={dataArrays[0]}
                                                        adjustColumn={["node", "price"]}
                                                    />
                                                </ContainerPriceNode>
                                            </StyledTableWrapper>
                                        ]}
                                    />
                                ) : (
                                    <TabsGeneric
                                        tabs={["Mensual", "Anual"]}
                                        tabvalue={tabIndex}
                                        setUpdateTab={setTabIndex}
                                        components={[
                                            <ChartPriceNodes
                                                key="monthly"
                                                labels={Array.isArray(monthlyData) ? monthlyData.map(item => item.dayNumber) : Array.from({ length: daysInMonth }, (_, index) => index + 1)}
                                                datasets={[
                                                    {
                                                        label: 'Carga',
                                                        data: Array.isArray(monthlyData) ? monthlyData.map(item => item.load) : Array.from({ length: daysInMonth }, (_, index) => 0),
                                                        borderColor: 'rgba(175 82 222)',
                                                        backgroundColor: 'rgba(175 82 222)',
                                                        ...commonDatasetProperties,
                                                    },
                                                    {
                                                        label: 'Descarga',
                                                        data: Array.isArray(monthlyData) ? monthlyData.map(item => item.unload) : Array.from({ length: daysInMonth }, (_, index) => 0),
                                                        borderColor: "rgb(255, 159, 64)",
                                                        backgroundColor: "rgba(255, 159, 64)",
                                                        ...commonDatasetProperties,
                                                    },
                                                ]}
                                                xTitle="Días"
                                                yTitle="$/MWh"
                                            />,
                                            <ChartPriceNodes
                                                key="annual"
                                                labels={Array.isArray(annualData) ? annualData.map(item => item.month) : []}
                                                datasets={[
                                                    {
                                                        label: 'Utilidad mensual',
                                                        data: Array.isArray(annualData) ? annualData.map(item => item.utility) : [],
                                                        borderColor: "rgb(255, 159, 64)",
                                                        backgroundColor: "rgba(255, 159, 64)",
                                                        ...commonDatasetProperties,
                                                    },
                                                ]}
                                                xTitle="Meses"
                                                yTitle="$/MWh"
                                            />,
                                        ]}
                                    />
                                )}
                            </StyledAccordionDetails>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CustomModal>
    );
};

export default NodeActivityLogModal;
