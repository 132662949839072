import React, { useEffect, useMemo } from 'react';
import { ContentSection } from '../../../components/Base/TitleSection/TitleSection.Style';
import { Grid, InputAdornment } from '@mui/material';
import { InputText } from '../../../components/Base/Inputs';
import { PrimaryButton } from '../../../components/Base/Buttons/Buttons';
import { useApplySettingsRegion, useSettingsRegion } from '../api/ExchangeRateApi';
import { useState } from 'react';
import { ConfirmationModal } from '../../../components/Modal/ConfirmationModal';


const RegionSettingsTabPanel = () => {
    const settingsRegion = useSettingsRegion();
    const [regData, setRegdata] = useState([]);
    const [utility, setUtility] = useState([]);
    const ApplySettingsRegion = useApplySettingsRegion();
    const [showModalAppliedRegions, setshowModalAppliedRegions] = useState(false);
    const [message, setMessage] = useState("");


    const handleSubmit = () => {
        let updatesuccess = true;

        regData.map((data,index) =>{
            const result = onSave(data);
            if(!result){
                updatesuccess = false;
            }
        });

        utility.map((data,index) => {
            const result = onSave(data);
            if(!result){
                updatesuccess = false;
            }
        });
        if(updatesuccess){
            setMessage("Se aplicaron los cambios con éxito");
            setshowModalAppliedRegions(true);
        } else {
            setshowModalAppliedRegions(false);
        }
        settingsRegion.refetch();
    }
    const onSave = async (data) => {
        let toback;
        const result = await ApplySettingsRegion.mutateAsync(data);
        toback = result ? true : false;
        return (toback);
    }

    useEffect(() => {
        if(settingsRegion.isSuccess && regData.length === 0){
            const collator = new Intl.Collator('en', { sensitivity: 'base' });
            settingsRegion.data.sort((x, y) => collator.compare(x.description, y.description));
            const auxData = [];
            const auxUtility = [];
            let count = 1;
            settingsRegion.data.map((data) => {
                if (data.description === "Utilidad" || data.description === "Tasa de Interes Anual") {
                    auxUtility.push({
                        description: data.description,
                        idConfig: data.idConfig,
                        value: data.value
                    })
                }else{
                    auxData.push({
                        description: data.description,
                        idConfig: data.idConfig,
                        regions: data.regions.length > 1 ? data.regions.join(', ') : data.regions[0],
                        value: data.value,
                        title: ("Región " + count)
                    })
                    count++;
                }
            })
            setUtility(auxUtility)
            setRegdata(auxData)
        }
    }, [settingsRegion]);

    const handleChange = (index, element) => {
        if(element.target.name === "Utilidad" || element.target.name === "Tasa de Interes Anual"){
            let numericValue = Number(element.target.value);

            if (numericValue < 0) {
                numericValue = 0;
            } else if (numericValue > 100) {
                numericValue = 100;
            }
            
            const newUtility = [...utility].sort((a, b) => b.description.localeCompare(a.description));
            newUtility[index]['value']= numericValue;
            setUtility(newUtility);
        }else{
            let numericValue = Number(element.target.value);
            const newData = [...regData]
            newData[index]['value']= numericValue;
            setRegdata(newData);
        }
    };

    const sortedUtility = useMemo(() => 
        [...utility].sort((a, b) => b.description.localeCompare(a.description)), 
        [utility]
    );
    
    return(
        <>
        <ContentSection container>
            <Grid container columnSpacing={4}>
                <Grid item container spacing={4}>
                    {sortedUtility.map((data,index) =>
                        <Grid item xs={4} key={index}>
                            <InputText
                                key={data.description}
                                name={data.description}
                                type="number"
                                title={data.description}
                                value={data.value}
                                onChange={(e) => handleChange(index, e)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                                inputProps={{
                                    min: 0
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item container spacing={4}>
                    {regData.map((data,index) =>
                        <Grid item xs={4} key={index}>
                            <InputText
                                key={"region_"+data.idConfig}
                                name={"region_"+data.idConfig}
                                type="text"
                                title={data.title}
                                value={data.regions}
                                disabled={true}
                            />
                        </Grid>

                    )}
                </Grid>
                <Grid item container spacing={4}>
                    {regData.map((data,index) =>
                        <Grid item xs={4} key={index}>
                            <InputText
                                type="number"
                                name={"hpunta_"+data.idConfig}
                                key={"hpunta_"+data.idConfig}
                                title={data.description}
                                value={data.value}
                                onChange={(e) => handleChange(index, e)}
                            />
                        </Grid>

                    )}
                </Grid>
                <Grid container justifyContent={"center"} alignItems={"center"} textAlign={"center"} marginTop={5}>
                    <Grid item xs={4}>
                        <PrimaryButton
                            handleClick={ handleSubmit }
                            text={"Aplicar"}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmationModal
                open={showModalAppliedRegions}
                onSucces={() => setshowModalAppliedRegions(false)}
                isAvailableCancel={false}
                isAvailableAcept={true}
                message={message}
                status={"success"}
            />
        </ContentSection>
    </>
    );
};

export default RegionSettingsTabPanel;