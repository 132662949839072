import React from 'react'
import { CalculationProvider } from '../context/CalculationContext'
import { NewCalculations } from './NewCalculations'

const NewCalculationsContainer = () => {
  return (
    <CalculationProvider>
      <NewCalculations />
    </CalculationProvider>
  )
}

export default NewCalculationsContainer