import { useContext, useEffect, useRef, useState } from "react";
import { AccordionDetails, AccordionSummary, Button, Dialog, Grid, Typography } from "@mui/material";
import { ViewSection } from "../ViewSection"
import { TabsGeneric } from "./TabPanel";
import { InvoiceDetails } from "./InvoiceDetails";
import { ExpandMore, Delete, Warning } from "@mui/icons-material";
import { StyledDetail, StyledDelete } from "./Styled.components";
import { CustomButton } from "../../../../components/Modal/Styled.components";
import { Loader } from "../../../../components/Base/Loader";
import { calculatePowerFactor } from "../../../../helpers/calculation";
import BatterySection from "../../sections/BatterySection/BatterySection";
import { Divider } from "../styled.components"
import { CalculationContext } from "../../context/CalculationContext";
import { CalcsCapacidad, CalcsCENACE, CalcsCritera, CalcsFactorPotencia, CalcsGeneracionB, CalcsGeneracionI, CalcsGeneracionP, CalcsSCNMEM, CalcsTotal, CalcsTransmision, calculateInitialVariables, calculateReceiptCalculation, CalsDistribucion, CalsSuministro, getTotalConsumption } from "../../calculationsUtils";

export const DetailPayContainer = ({ isDoublePeriod, data, setDeletePrice, billedDays, setUpdateData, isVisibility }) => {
    const {
        state: {
            electricityPayDetailAux,
            baterryCost,
            baseBatteryCalculationByQuotation,
            utilityPercent,
            exchangeRate,
            batteryInitialCalculation,
            pricePayAux,
            priceEnergy,
            auxQuation,
            isSinglePeriod,
            electricityPay,
            hasLowVoltage,
        },
        dispatch,
        skipEffectRef,
        isChangeData,
        setIsChangeData
    } = useContext(CalculationContext);
    const [showdeleteModal, setDeleteModal] = useState(false);
    const [indexItem, setIndexItem] = useState(null);
    const [selectCoti, setSelectCoti] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [updatetab, setUpdateTab] = useState(0);
    const [localState, setLocalState] = useState(null);
    const [arrayState, setArrayState] = useState([]);
    const [dataFull, setDataFull] = useState([]);
    // const [auxQuation, setAuxQuation] = useState(0);
    const [combinedData, setCombinedData] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [newcotizacion, setNewCotizacion] = useState(false);
    const [combineTotal, setCombineTotal] = useState(null);
    const [docorign, setDocOriginal] = useState([]);
    // const skipEffectRef = useRef(false);


    const handleSelectDelete = (item, index) => {
        setSelectCoti(item);
        setDeleteModal(true);
        setIndexItem(index);
    }

    const handleDelete = () => {
        const auxstate = JSON.parse(JSON.stringify(arrayState));
        const auxdataFull = JSON.parse(JSON.stringify(dataFull));

        // Eliminar el elemento en el índice `indexItem`
        auxstate.splice(indexItem, 1);
        auxdataFull.splice(indexItem, 1);
        skipEffectRef.current = true;

        setArrayState(auxstate);
        setDataFull(auxdataFull);
        setDeletePrice(indexItem);
        setExpanded(false);
        // setAuxQuation(0);
        setDeleteModal(false);
        dispatch({ type: 'DELETE_AUX_ELECTRICITY_PAY_DETAIL', payload: indexItem });

    };

    const validateNewCoti = (ch_totalConsumption, maxDemand) => {
        if (ch_totalConsumption === 0 && maxDemand === 0 && localState.kvarh === '0' && localState.kwhMax === "0" && localState.factorPotencia === "0") {
            return true
        }
        return false
    }

    //IMPORTE A PAGAR
    const ImportePagar = () => {
        const ch_totalConsumption = Number(localState.consumoBase) + Number(localState.consumoIntermedia) + Number(localState.consumoPunta);
        const measurement = localState.measurement;
        const tp_fixedLoad = (!isNaN(localState.supplyCost)) ? Number(localState.supplyCost) : 0;
        const tp_energy = (!isNaN(localState.totalPriceKw) && !isNaN(localState.totalPriceKwh)) ? Number(localState.totalPriceKw) + Number(localState.totalPriceKwh) : 0;
        const tp_lowVoltage = measurement === 0 || !hasLowVoltage ? 0 : 0.02 * (tp_fixedLoad + tp_energy);
        const powerFactorValue = calculatePowerFactor(Number(localState.factorPotencia), tp_fixedLoad, tp_energy, tp_lowVoltage, Number(localState.ch_powerFactorBonus), Number(localState.ch_powerFactorPenalty), Number(localState.ch_powerFactorCalculationRule));
        const tp_subtotal = tp_fixedLoad + tp_energy + tp_lowVoltage + powerFactorValue;
        const iva = tp_subtotal * 0.16;
        const tp_invoicingPeriodTotal = tp_subtotal + iva;
        const tp_lightingFee = Number(localState.lightingFee);
        const invoiceTotal = tp_invoicingPeriodTotal + tp_lightingFee;
        const ch_averagePrice = (ch_totalConsumption !== 0 && tp_subtotal !== 0) ? tp_subtotal / ch_totalConsumption : 0;
        const maxDemand = Math.max(Number(localState.demandaBase), Number(localState.demandaIntermedia), Number(localState.demandaPunta));
        const ch_FactorDeCarga = (ch_totalConsumption !== 0 && maxDemand !== 0) ? ch_totalConsumption / (maxDemand * 24 * Number(localState.billedDays)) : 0;

        const auxDataFull = JSON.parse(JSON.stringify((data.length === 1) ? docorign : dataFull));

        const historicObj = {
            periodTypeId: Number(localState.periodTypeId) ?? auxDataFull[auxQuation].data[updatetab]?.periodTypeId,
            amountBreakdownId: (auxQuation === 0) ? auxDataFull[auxQuation].data[updatetab]?.amountBreakdownId ?? 0 : (validateNewCoti(ch_totalConsumption, maxDemand)) ? 0 : dataFull[auxQuation].data[updatetab]?.amountBreakdownId ?? 0,
            fixedCharge: (auxQuation === 1) ? isNaN(tp_fixedLoad) ? 0 : tp_fixedLoad : (validateNewCoti(ch_totalConsumption, maxDemand)) ? 0 : isNaN(tp_fixedLoad) ? 0 : tp_fixedLoad,
            energy: (auxQuation === 1) ? isNaN(tp_energy) ? 0 : tp_energy : (validateNewCoti(ch_totalConsumption, maxDemand)) ? 0 : isNaN(tp_energy) ? 0 : tp_energy,
            lowVoltage2p: (auxQuation === 1) ? isNaN(tp_lowVoltage) ? 0 : tp_lowVoltage : (validateNewCoti(ch_totalConsumption, maxDemand)) ? 0 : isNaN(tp_lowVoltage) ? 0 : tp_lowVoltage,
            powerFactor: (auxQuation === 1) ? isNaN(powerFactorValue) ? 0 : powerFactorValue : (validateNewCoti(ch_totalConsumption, maxDemand)) ? 0 : isNaN(powerFactorValue) ? 0 : powerFactorValue,
            subtotal: (auxQuation === 1) ? isNaN(tp_subtotal) ? 0 : tp_subtotal : (validateNewCoti(ch_totalConsumption, maxDemand)) ? 0 : isNaN(tp_subtotal) ? 0 : tp_subtotal,
            tax: (auxQuation === 1) ? isNaN(iva) ? 0 : iva : (validateNewCoti(ch_totalConsumption, maxDemand)) ? 0 : isNaN(iva) ? 0 : iva,
            billingPeriod: (auxQuation === 1) ? isNaN(tp_invoicingPeriodTotal) ? 0 : tp_invoicingPeriodTotal : (validateNewCoti(ch_totalConsumption, maxDemand)) ? 0 : isNaN(tp_invoicingPeriodTotal) ? 0 : tp_invoicingPeriodTotal,
            lightingFee: (auxQuation === 1) ? isNaN(tp_lightingFee) ? 0 : tp_lightingFee : (validateNewCoti(ch_totalConsumption, maxDemand)) ? 0 : isNaN(tp_lightingFee) ? 0 : tp_lightingFee,
            totalAmount: (auxQuation === 1) ? isNaN(invoiceTotal) ? 0 : invoiceTotal : (validateNewCoti(ch_totalConsumption, maxDemand)) ? 0 : isNaN(invoiceTotal) ? 0 : invoiceTotal,
        };

        const consumptionObj = {
            consumptionId: (auxQuation === 0) ? data[0].details[updatetab].consumption[0].consumptionId : 0,
            totalConsumption: ch_totalConsumption,
            receiptDetailId: auxDataFull[0].details[updatetab].consumption[0]?.receiptDetailId,
            monthlyMinimum: Number(localState.supplyCost),
            averagePrice: ch_averagePrice,
            fpBonus: Number(localState.ch_powerFactorBonus),
            loadFactor: ch_FactorDeCarga,
            fpPenalty: Number(localState.ch_powerFactorPenalty)
        };

        const criteriaObj = {
            consumptionId: (auxQuation === 0) ? data[0].details[updatetab].criteria[0].consumptionId : 0,
            capacityA: Number(localState.capacityA ?? 0),
            capacityB: Number(localState.capacityB ?? 0),
            distributionA: Number(localState.distributionA ?? 0),
            distributionB: Number(localState.distributionB ?? 0),
        };

        auxDataFull[auxQuation].details[updatetab].baseConsumption = localState.consumoBase;
        auxDataFull[auxQuation].details[updatetab].baseDemand = localState.demandaBase;
        auxDataFull[auxQuation].details[updatetab].intermediateConsumption = localState.consumoIntermedia;
        auxDataFull[auxQuation].details[updatetab].intermediateDemand = localState.demandaIntermedia;
        auxDataFull[auxQuation].details[updatetab].peakConsumption = localState.consumoPunta;
        auxDataFull[auxQuation].details[updatetab].peakDemand = localState.demandaPunta;
        auxDataFull[auxQuation].details[updatetab].kwmaxAm = localState.kwhMax;
        auxDataFull[auxQuation].details[updatetab].reactivePower = localState.kvarh;
        auxDataFull[auxQuation].details[updatetab].powerFactor = isNaN(localState.factorPotencia) ? '0' : localState.factorPotencia;
        auxDataFull[auxQuation].details[updatetab].billedDays = localState.billedDays;

        auxDataFull[auxQuation].data[updatetab] = historicObj;


        const initialVariables = calculateInitialVariables(auxDataFull[auxQuation].details[updatetab], batteryInitialCalculation, isSinglePeriod, priceEnergy, electricityPay, utilityPercent);


        // Sección de cálculos del recibo asociado a la cotización por costo de batería
        let receiptDetailCalculation = calculateReceiptCalculation(
            baterryCost[auxQuation],
            initialVariables,
            exchangeRate,
            batteryInitialCalculation,
            pricePayAux[auxQuation + 1].data[updatetab],
            invoiceTotal,
            pricePayAux[auxQuation + 1].details[updatetab].receiptCalculation[0],
            localState,
            historicObj.lowVoltage2p,
        );

        receiptDetailCalculation = {
            ...receiptDetailCalculation,
            receiptCalculationId: auxQuation === 0 ? auxDataFull[auxQuation].details[updatetab].receiptCalculation[0]?.receiptCalculationId ?? 0 : 0,
        }

        if (auxQuation === 1 && consumptionObj.consumptionId !== 0 && criteriaObj.consumptionId !== 0 && receiptDetailCalculation.receiptCalculationId !== 0) {
            auxDataFull[0].details[updatetab].consumption[0] = consumptionObj;
            auxDataFull[0].details[updatetab].criteria[0] = criteriaObj;
            auxDataFull[0].details[updatetab].receiptCalculation[0] = receiptDetailCalculation;
        } else {
            auxDataFull[auxQuation].details[updatetab].consumption[0] = consumptionObj;
            auxDataFull[auxQuation].details[updatetab].criteria[0] = criteriaObj;
            auxDataFull[auxQuation].details[updatetab].receiptCalculation[0] = receiptDetailCalculation;
        }
        if (newcotizacion && combineTotal) {
            combineTotal?.map((value, index) => {
                auxDataFull[auxQuation].details[index].listEnergyCost = value;
            });
            setNewCotizacion(false);
        } else if (combinedData.length > 0) {
            if (auxDataFull[auxQuation].details[updatetab].receiptDetailId === combinedData[0].billDetailId) {
                auxDataFull[auxQuation].details[updatetab].listEnergyCost = combinedData;
            }
        }
        setUpdateData({ datafull: auxDataFull, numQuotation: auxQuation });
        //setDataFull(auxDataFull);
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setUpdateTab(0);
        if (electricityPayDetailAux && electricityPayDetailAux[panel]) {
            dispatch({ type: 'SET_AUX_QUOTATION', payload: parseInt(panel) });
            setLocalState(electricityPayDetailAux[panel][0]);
        }
    };

    useEffect(() => {
        if (data.length > 0) {
            const auxdata = JSON.parse(JSON.stringify(data));
            if (data.length > 1) setDataFull(auxdata);
            if (data.length === 1) setDocOriginal(auxdata);

            if (isVisibility) {
                // En modo detalle, recalculamos arrayState para que tenga la misma cantidad de registros que data
                const newArrayState = auxdata.map((item) => {
                    // Suponiendo que cada cotización debe contener un arreglo de "detalle" con cierta cantidad de registros (por ejemplo, isDoublePeriod)
                    let auxstateobj = [];
                    let condition = 0;
                    // Aquí se construye auxstateobj para cada cotización usando la data de item.details[condition] u otros cálculos
                    do {
                        console.log('item', item)
                        auxstateobj.push({
                            consumoBase: item.details[condition].baseConsumption ?? 0,
                            consumoIntermedia: item.details[condition].intermediateConsumption ?? 0,
                            consumoPunta: item.details[condition].peakConsumption ?? 0,
                            demandaBase: item.details[condition].baseDemand ?? 0,
                            demandaIntermedia: item.details[condition].intermediateDemand ?? 0,
                            demandaPunta: item.details[condition].peakDemand ?? 0,
                            kwhMax: item.details[condition].kwmaxAm ?? 0,
                            cargaConectada: item.details[condition].connectedLoadKw ?? 0,
                            demandaContratada: item.details[condition].contractedDemandKw ?? 0,
                            kvarh: item.details[condition].reactivePower ?? 0,
                            factorPotencia: item.details[condition].powerFactor ?? 0,
                            billedDays: item.details[condition].billedDays,
                            supplyPrice: 0,
                            capacityA: item?.details[condition]?.criteria[0]?.capacityA ?? 0,
                            capacityB: item?.details[condition]?.criteria[0]?.capacityB ?? 0,
                            distributionA: item?.details[condition]?.criteria[0]?.distributionA ?? 0,
                            distributionB: item?.details[condition]?.criteria[0]?.distributionB ?? 0,
                            periodTypeId: item.details[condition].periodTypeId,
                            idCotizacion: 0,
                            supplyCost: 0,
                            supplyTotalAmount: 0,
                            distributionCostKw: 0,
                            distributionTotalAmount: 0,
                            transmitionCostKwh: 0,
                            transmitionTotalAmount: 0,
                            cenaeOperationCostKwh: 0,
                            cenaeOperationTotalAmount: 0,
                            generationBCostKwh: 0,
                            generationBTotalAmount: 0,
                            generationICostKwh: 0,
                            generationITotalAmount: 0,
                            generationPCostKwh: 0,
                            generationPTotalAmount: 0,
                            capacityCostKw: 0,
                            capacityTotalAmount: 0,
                            memCostKwh: 0,
                            memTotalAmount: 0,
                            totalPrice: 0,
                            totalPriceKw: 0,
                            totalPriceKwh: 0,
                            granTotal: 0,
                            ch_totalKw: 0,
                            ch_powerFactorCalculationRule: 0,
                            ch_minimoMensual: 0,
                            ch_powerFactorBonus: 0,
                            ch_powerFactorPenalty: 0,
                            isDoublePeriod: isDoublePeriod,
                            calculationTypeId: item.details[condition].periodTypeId,
                            totalKw: item.details[condition].totalKw ?? 0,
                            lightingFee: item.details[condition].lightingFee ?? 0,
                            measurement: item.details[condition].measurement ?? 0,
                            lowVoltage2p: item?.data[condition]?.lowVoltage2p ?? 0,
                            requiredInvestmentSAEUSD: item?.details?.[condition]?.receiptCalculation?.[0]?.requiredInvestmentSAEUSD ?? 0,
                            requiredInvestmentSAEMXN: item?.details?.[condition]?.receiptCalculation?.[0]?.requiredInvestmentSAEMXN ?? 0,
                            utilityPercent: item?.details?.[condition]?.receiptCalculation?.[0]?.utility ?? 0,
                            roiMXN: item?.details?.[condition]?.receiptCalculation?.[0]?.roiMXN ?? 0,
                            roiUSD: item?.details?.[condition]?.receiptCalculation?.[0]?.roiUSD ?? 0,
                            batteryCost: item?.details?.[condition]?.receiptCalculation?.[0]?.batteryCost ?? 0,
                        });
                        condition++;
                    } while (condition < isDoublePeriod);
                    return auxstateobj;
                });
                setArrayState(newArrayState);
                // En modo detalle podrías actualizar localState de forma distinta (o dejarlo intacto)
                // setLocalState(newArrayState[auxQuation][updatetab]);  // Opcional, según tu lógica
                // También despachar la acción si es necesario:
                dispatch({ type: 'SET_AUX_ELECTRICITY_PAY_DETAIL', payload: newArrayState });
            } else {
                // Agregar los datos de la cotización a la variable de estado
                if (arrayState.length < data.length) {
                    const aux = JSON.parse(JSON.stringify(arrayState));
                    let auxstateobj = []
                    let condition = 0;


                    const { capacityA, capacityB, distributionA, distributionB } = CalcsCritera(
                        data[aux.length].details[condition].baseConsumption ?? 0,
                        data[aux.length].details[condition].intermediateConsumption ?? 0,
                        data[aux.length].details[condition].peakConsumption ?? 0,
                        data[aux.length].details[condition].peakDemand ?? 0,
                        billedDays,
                        data[aux.length].details[condition].baseDemand ?? 0,
                        data[aux.length].details[condition].intermediateDemand ?? 0
                    )

                    const totalConsumption = getTotalConsumption(
                        data[aux.length].details[condition].baseConsumption ?? 0,
                        data[aux.length].details[condition].intermediateConsumption ?? 0,
                        data[aux.length].details[condition].peakConsumption ?? 0
                    )

                    const { supplyCost, supplyTotalAmount } = CalsSuministro(
                        isDoublePeriod,
                        priceEnergy?.supplyValue ?? 0,
                        data[aux.length].details[condition].tariffTypeId,
                        data[aux.length].details[condition].billedDays,
                        billedDays
                    );

                    const { distributionCostKw, distributionTotalAmount } = CalsDistribucion(
                        isDoublePeriod,
                        priceEnergy?.distributionValue ?? 0,
                        data[aux.length].details[condition].tariffTypeId,
                        distributionA,
                        distributionB,
                        data[aux.length].details[condition].billedDays,
                        billedDays
                    )

                    const { transmitionCostKwh, transmitionTotalAmount } = CalcsTransmision(totalConsumption, priceEnergy?.transmitionValue)

                    const { capacityCostKw, capacityTotalAmount } = CalcsCapacidad(
                        isDoublePeriod,
                        priceEnergy?.capacityValue,
                        data[aux.length].details[condition].tariffTypeId,
                        capacityA,
                        capacityB,
                        data[aux.length].details[condition].billedDays,
                        billedDays
                    )

                    const { cenaeOperationCostKwh, cenaeOperationTotalAmount } = CalcsCENACE(totalConsumption, priceEnergy?.cenaeOperationValue)

                    const { generationBCostKwh, generationBTotalAmount } = CalcsGeneracionB(data[aux.length].details[condition].baseConsumption ?? 0, priceEnergy?.generationBValue)

                    const { generationICostKwh, generationITotalAmount } = CalcsGeneracionI(data[aux.length].details[condition].intermediateConsumption ?? 0, priceEnergy?.generationIValue)

                    const { generationPCostKwh, generationPTotalAmount } = CalcsGeneracionP(data[aux.length].details[condition].peakConsumption ?? 0, priceEnergy?.generationPValue)

                    const { memCostKwh, memTotalAmount } = CalcsSCNMEM(totalConsumption, priceEnergy?.memValue)

                    const { totalPrice, totalPriceKw, totalPriceKwh, granTotal } = CalcsTotal(supplyCost, distributionCostKw, capacityCostKw, transmitionCostKwh, cenaeOperationCostKwh, generationBCostKwh, generationICostKwh, generationPCostKwh, memCostKwh)

                    const { ch_totalKw, ch_powerFactorCalculationRule, ch_minimoMensual, ch_powerFactorBonus, ch_powerFactorPenalty } = CalcsFactorPotencia(
                        data[aux.length].details[condition].totalKw ?? 0,
                        supplyCost,
                        data[aux.length].details[condition].powerFactor ?? 0
                    )



                    do {
                        auxstateobj.push({
                            consumoBase: data[aux.length].details[condition].baseConsumption ?? 0,
                            consumoIntermedia: data[aux.length].details[condition].intermediateConsumption ?? 0,
                            consumoPunta: data[aux.length].details[condition].peakConsumption ?? 0,
                            demandaBase: data[aux.length].details[condition].baseDemand ?? 0,
                            demandaIntermedia: data[aux.length].details[condition].intermediateDemand ?? 0,
                            demandaPunta: data[aux.length].details[condition].peakDemand ?? 0,
                            kwhMax: data[aux.length].details[condition].kwmaxAm ?? 0,
                            cargaConectada: data[aux.length].details[condition].connectedLoadKw ?? 0,
                            demandaContratada: data[aux.length].details[condition].contractedDemandKw ?? 0,
                            kvarh: data[aux.length].details[condition].reactivePower ?? 0,
                            factorPotencia: data[aux.length].details[condition].powerFactor ?? 0,
                            billedDays: data[aux.length].details[condition].billedDays,
                            supplyPrice: 0,
                            capacityA: data[aux.length]?.details[condition]?.criteria[0]?.capacityA ?? 0,
                            capacityB: data[aux.length]?.details[condition]?.criteria[0]?.capacityB ?? 0,
                            distributionA: data[aux.length]?.details[condition]?.criteria[0]?.distributionA ?? 0,
                            distributionB: data[aux.length]?.details[condition]?.criteria[0]?.distributionB ?? 0,
                            periodTypeId: data[aux.length].details[condition].periodTypeId,
                            idCotizacion: 0,
                            supplyCost: supplyCost,
                            supplyTotalAmount: supplyTotalAmount,
                            distributionCostKw: distributionCostKw,
                            distributionTotalAmount: distributionTotalAmount,
                            transmitionCostKwh: transmitionCostKwh,
                            transmitionTotalAmount: transmitionTotalAmount,
                            cenaeOperationCostKwh: cenaeOperationCostKwh,
                            cenaeOperationTotalAmount: cenaeOperationTotalAmount,
                            generationBCostKwh: generationBCostKwh,
                            generationBTotalAmount: generationBTotalAmount,
                            generationICostKwh: generationICostKwh,
                            generationITotalAmount: generationITotalAmount,
                            generationPCostKwh: generationPCostKwh,
                            generationPTotalAmount: generationPTotalAmount,
                            capacityCostKw: capacityCostKw,
                            capacityTotalAmount: capacityTotalAmount,
                            memCostKwh: memCostKwh,
                            memTotalAmount: memTotalAmount,
                            totalPrice: totalPrice,
                            totalPriceKw: totalPriceKw,
                            totalPriceKwh: totalPriceKwh,
                            granTotal: granTotal,
                            ch_totalKw: ch_totalKw,
                            ch_powerFactorCalculationRule: ch_powerFactorCalculationRule,
                            ch_minimoMensual: ch_minimoMensual,
                            ch_powerFactorBonus: ch_powerFactorBonus,
                            ch_powerFactorPenalty: ch_powerFactorPenalty,
                            isDoublePeriod: isDoublePeriod,
                            calculationTypeId: data[aux.length].details[condition].periodTypeId,
                            totalKw: data[aux.length].details[condition].totalKw ?? 0,
                            lightingFee: data[aux.length].details[condition].lightingFee ?? 0,
                            measurement: data[aux.length].details[condition].measurement ?? 0,
                            lowVoltage2p: data[aux.length]?.data[condition]?.lowVoltage2p ?? 0,
                            requiredInvestmentSAEUSD: data[aux.length]?.details?.[condition]?.receiptCalculation?.[0]?.requiredInvestmentSAEUSD ?? 0,
                            requiredInvestmentSAEMXN: data[aux.length]?.details?.[condition]?.receiptCalculation?.[0]?.requiredInvestmentSAEMXN ?? 0,
                            utilityPercent: data[aux.length]?.details?.[condition]?.receiptCalculation?.[0]?.utility ?? 0,
                            roiMXN: data[aux.length]?.details?.[condition]?.receiptCalculation?.[0]?.roiMXN ?? 0,
                            roiUSD: data[aux.length]?.details?.[condition]?.receiptCalculation?.[0]?.roiUSD ?? 0,
                            batteryCost: data[aux.length]?.details?.[condition]?.receiptCalculation?.[0]?.batteryCost ?? 0,
                            // initialExpensePercent: data[aux.length]?.details?.[condition]?.receiptCalculation?.[0]?.initialExpensePercent ?? 0,
                        });
                        condition += 1;
                    } while (condition < isDoublePeriod);
                    aux.push(auxstateobj)
                    setArrayState(aux);
                    dispatch({ type: 'SET_AUX_ELECTRICITY_PAY_DETAIL', payload: aux });
                    if (!isVisibility) setLocalState(aux[aux.length - 1][0]);
                    dispatch({ type: 'SET_AUX_QUOTATION', payload: aux.length - 1 });
                    // setAuxQuation(aux.length - 1);
                    setNewCotizacion(true);
                    setExpanded(false);
                }
            }


        }
    }, [data, isVisibility]);

    useEffect(() => {

        if (localState && arrayState.length > 0 && !isVisibility) {
            if ((arrayState[auxQuation][updatetab].consumoBase !== Number(localState.consumoBase)
                || arrayState[auxQuation][updatetab].consumoIntermedia !== Number(localState.consumoIntermedia)
                || arrayState[auxQuation][updatetab].consumoPunta !== Number(localState.consumoPunta)
                || arrayState[auxQuation][updatetab].demandaBase !== Number(localState.demandaBase)
                || arrayState[auxQuation][updatetab].demandaIntermedia !== Number(localState.demandaIntermedia)
                || arrayState[auxQuation][updatetab].demandaPunta !== Number(localState.demandaPunta)
                || arrayState[auxQuation][updatetab].kvarh !== Number(localState.kvarh)
                || arrayState[auxQuation][updatetab].batteryCost !== Number(localState.batteryCost)
            )) {
                let aux = JSON.parse(JSON.stringify(arrayState));
                aux[auxQuation][updatetab] = localState;
                setArrayState(aux);
                console.log('arrayState - localstate', arrayState)
                dispatch({ type: 'SET_AUX_ELECTRICITY_PAY_DETAIL', payload: aux });
                ImportePagar();
            }
        }
    }, [localState]);

    useEffect(() => {
        if (combineTotal && newcotizacion) {
            const auxDataFull = JSON.parse(JSON.stringify((data.length === 1) ? docorign : dataFull));
            combineTotal?.forEach((value, index) => {
                if (auxDataFull[auxQuation]?.details[index]) {
                    auxDataFull[auxQuation].details[index].listEnergyCost = value;
                }
            });
            if (auxDataFull.length === 1) {
                setCombinedData(combineTotal[updatetab]);
            }
            if (auxDataFull.length > 1) {
                data[0]?.details?.forEach((value, index) => {
                    if (auxDataFull[auxQuation]?.details[index] && value.listEnergyCost) {
                        auxDataFull[auxQuation].details[index].listEnergyCost = value.listEnergyCost.map(item => ({
                            ...item,
                            energyCostId: 0
                        }));
                    }
                });
                setCombinedData(auxDataFull[auxQuation].details[updatetab].listEnergyCost);
            }
            setUpdateData({ datafull: auxDataFull, numQuotation: auxQuation });
            setNewCotizacion(false);
        }
    }, [combineTotal]);

    useEffect(() => {
        const auxDataFull = JSON.parse(JSON.stringify((data.length === 1) ? docorign : dataFull));
        if (auxDataFull.length > 0 && auxDataFull[auxQuation]?.details[updatetab]?.listEnergyCost) {
            setCombinedData(auxDataFull[auxQuation].details[updatetab].listEnergyCost);
        }
    }, [updatetab, auxQuation]);

    useEffect(() => {
        // Si skipEffectRef.current está activo, lo reiniciamos y salimos sin hacer nada.
        if (skipEffectRef.current) {
            skipEffectRef.current = false;
            return;
        }

        if (isChangeData) {
            setIsChangeData(false)
            return
        }

        if (baterryCost && baterryCost[auxQuation]) {
            setLocalState((prev) => {
                // Solo actualizamos si batteryCost es diferente al valor actual
                if (prev.batteryCost && prev.batteryCost === baterryCost[auxQuation]) return prev;
                return {
                    ...prev,
                    batteryCost: baterryCost[auxQuation],
                };
            });
        }

    }, [baterryCost, auxQuation]);


    return (
        <ViewSection>
            <Grid container>
                {arrayState.length > 0 && data?.map((value, index) => (
                    <Grid key={index} item xs={12}>
                        <StyledDetail expanded={expanded === `${index}`} onChange={handleChange(`${index}`)}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Grid container>
                                    <Grid item xs={11} textAlign={"left"}>
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            {(index === 0) ? `Detalles del periodo facturado (${value.titles})` : value.titles}
                                        </Typography>
                                    </Grid>
                                    {(index > 0 && !isVisibility) ? <Grid item xs={1} textAlign={"center"}><Delete className="IconDelete" onClick={() => handleSelectDelete(value, index)} /></Grid> : null}
                                </Grid>

                            </AccordionSummary>
                            <AccordionDetails>
                                {isDoublePeriod === 1 ? (
                                    value.details.map((item, indexIn) => (
                                        <InvoiceDetails
                                            key={indexIn}
                                            data={item}
                                            numQuotation={index}
                                            quoteId={value.idCotizacion}
                                            isDoublePeriod={isDoublePeriod}
                                            color={value.color}
                                            receiptId={item.receiptId}
                                            billedDays={billedDays}
                                            setUpdateData={setUpdateData}
                                            localState={(!isVisibility) ? arrayState[auxQuation][updatetab] : item}
                                            setLocalState={setLocalState}
                                            combinedData={(!isVisibility) ? combinedData : item.energyCosts}
                                            setCombinedData={setCombinedData}
                                            isVisibility={isVisibility}
                                            listenergyCost={value.details}
                                            newCotizacion={newcotizacion}
                                            setNewCotizacion={setNewCotizacion}
                                            combineTotal={combineTotal}
                                            setCombineTotal={setCombineTotal}
                                            auxQuotation={auxQuation}
                                            updatetab={updatetab}
                                        />
                                    ))
                                ) : (
                                    <TabsGeneric
                                        tabs={value.details.map(item => `Periodo ${item.periodTypeId}`)}
                                        tabvalue={updatetab}
                                        setUpdateTab={setUpdateTab}
                                        components={value.details.map((item, index) => (
                                            <InvoiceDetails
                                                key={item.periodTypeId}
                                                data={item}
                                                numQuotation={index}
                                                quoteId={value.idCotizacion}
                                                isDoublePeriod={isDoublePeriod}
                                                color={value.color}
                                                receiptId={item.receiptId}
                                                billedDays={billedDays}
                                                localState={(!isVisibility) ? arrayState[auxQuation][updatetab] : item}
                                                setUpdateData={setUpdateData}
                                                setLocalState={setLocalState}
                                                combinedData={(!isVisibility) ? combinedData : item.energyCosts}
                                                setCombinedData={setCombinedData}
                                                listenergyCost={value.details}
                                                newCotizacion={newcotizacion}
                                                setNewCotizacion={setNewCotizacion}
                                                combineTotal={combineTotal}
                                                setCombineTotal={setCombineTotal}
                                                isVisibility={isVisibility}
                                                sizeData={dataFull.length}
                                                auxQuotation={auxQuation}
                                                updatetab={updatetab}
                                            />
                                        ))}
                                    />
                                )}

                                <Grid item xs={12} >
                                    <ViewSection
                                        title={(<Typography variant="subtitle1">Batería</Typography>)}
                                        divider={(<Divider />)}
                                    >
                                        <Grid container>
                                            <Grid item container justifyContent={"flex-end"} alignItems={"center"}>
                                                {pricePayAux && pricePayAux.length > 0 && pricePayAux[auxQuation + 1] && (
                                                    <BatterySection
                                                        color={value.color}
                                                        data={pricePayAux[auxQuation + 1].details[updatetab].receiptCalculation[0]}
                                                        updatetab={updatetab}
                                                        auxQuotation={auxQuation}
                                                        isVisibility={isVisibility} />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </ViewSection>
                                </Grid>
                            </AccordionDetails>
                        </StyledDetail>
                    </Grid>
                ))}
            </Grid>
            {showdeleteModal && <Dialog open={showdeleteModal}>
                <StyledDelete container justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                    <Grid item xs={12} marginBottom={3}><Warning /></Grid>
                    <Grid item xs={12} marginBottom={2}>¿Está seguro que desea eliminar la {(selectCoti) ? selectCoti?.titles : ""}?</Grid>
                    <Grid item xs={12} container columnSpacing={2} textAlign={"center"} justifyContent={"center"} alignItems={"center"} marginTop={3} marginBottom={3}>
                        <Grid item xs={4}>
                            <Button variant="outlined" color="error" onClick={() => setDeleteModal(false)} fullWidth>
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <CustomButton
                                fullWidth
                                variant="contained"
                                onClick={(e) => handleDelete()}
                                disabled={isLoading}
                            >
                                {isLoading ? <Loader size={'30px'} loading={isLoading} /> : "Aceptar"}
                            </CustomButton>
                        </Grid>
                    </Grid>
                </StyledDelete>
            </Dialog>}
        </ViewSection>
    )
}