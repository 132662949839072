import React, { useContext, useEffect } from 'react';
import { Box, Grid, Typography } from "@mui/material";
import { Divider, StyledTextField, StyledTextFieldReadOnly } from "../styled.components";
import { ViewSection } from "../ViewSection";
import TariffTableDetail from "./TariffTableDetail";
import { useGetElectricityBillCost } from '../../api/CalculationsApi';
import {  calculatePowerFactorBonus, calculatePowerFactorPenalty, getCriteria, getPowerFactorCalculationRule, toDegrees, toRadians } from '../../../../helpers/calculation';
import { CalculationContext } from '../../context/CalculationContext';

export const InvoiceDetails = ({
    data,
    quoteId,
    isDoublePeriod,
    color,
    receiptId,
    numQuotation,
    billedDays,
    newCotizacion,
    listenergyCost,
    localState,
    combinedData,
    setNewCotizacion,
    setCombinedData,
    setUpdateData,
    setLocalState,
    setCombineTotal,
    sizeData,
    isVisibility,
    auxQuotation,
    updatetab
}) => {
    const {
        state: {
            priceEnergy,
            electiricityTariff,
        },
    } = useContext(CalculationContext);
    // const [valueCost, setValueCost] = useState([]);

    // const electricityTariff = useGetElectricityBillTariff(data?.receiptId);
    const electricityCost = useGetElectricityBillCost(data?.receiptId, quoteId);

    // const supplyValue = valueCost.find(item => item.conceptName === "Suministro")?.value || 0;
    // const distributionValue = valueCost.find(item => item.conceptName === "Distribucion (KW)")?.value || 0;
    // const transmitionValue = valueCost.find(item => item.conceptName === "Transmision (KWh)")?.value || 0;
    // const cenaeOperationValue = valueCost.find(item => item.conceptName === "CENACE (KWh)")?.value || 0;
    // const generationBValue = valueCost.find(item => item.conceptName === "Generacion B(KWh)")?.value || 0;
    // const generationIValue = valueCost.find(item => item.conceptName === "Generacion I (KWh)")?.value || 0;
    // const generationPValue = valueCost.find(item => item.conceptName === "Generacion P (KWh)")?.value || 0;
    // const capacityValue = valueCost.find(item => item.conceptName === "Capacidad (KW)")?.value || 0;
    // const memValue = valueCost.find(item => item.conceptName === "SCnMEM (KWh)")?.value || 0;

    const validateNumber = (value) => {
        if (isNaN(value) || !isFinite(value)) {
            return 0;
        }
        return value;
    };

    const handleCombineteData = (receiptDetailId) => {
        //const data1 = electricityTariff.data;
        //const data2 = electricityCost.data;

        // const data1 = electricityTariff?.data || [];
        const data1 = electiricityTariff || [];
        const data2 = electricityCost?.data || [];

        const baseSupply = {};
        const baseDistribution = {};
        const baseTransmition = {};
        const baseCenace = {};
        const baseGenerationB = {};
        const baseGenerationI = {};
        const baseGenerationP = {};
        const baseCapacity = {};
        const baseMem = {};

        const combined = data1.map(item1 => {
            const matchingItem = data2.find(item2 => item2.conceptId === item1.conceptId && item2.billDetailId === receiptDetailId);
            if (matchingItem) {
                switch (item1.conceptId) {
                    case 1:
                        return {

                            ...item1,
                            energyCostId: matchingItem.energyCostId,
                            cost: localState?.supplyCost !== 0 ? localState?.supplyCost : matchingItem.cost,
                            totalAmount: localState?.supplyTotalAmount !== 0 ? localState?.supplyTotalAmount : matchingItem.totalAmount,
                            billDetailId: matchingItem.billDetailId,
                        };
                    case 2:
                        return {
                            ...item1,
                            energyCostId: matchingItem.energyCostId,
                            costPerKw: localState?.distributionCostKw !== 0 ? localState?.distributionCostKw : matchingItem.costPerKw,
                            totalAmount: localState?.distributionTotalAmount !== 0 ? localState?.distributionTotalAmount : matchingItem.totalAmount,
                            billDetailId: matchingItem.billDetailId,
                        };
                    case 3:
                        return {
                            ...item1,
                            energyCostId: matchingItem.energyCostId,
                            costPerKwh: localState?.transmitionCostKwh !== 0 ? localState?.transmitionCostKwh : matchingItem.costPerKwh,
                            totalAmount: localState?.transmitionTotalAmount !== 0 ? localState?.transmitionTotalAmount : matchingItem.totalAmount,
                            billDetailId: matchingItem.billDetailId,
                        };
                    case 4:
                        return {
                            ...item1,
                            energyCostId: matchingItem.energyCostId,
                            costPerKwh: localState?.cenaeOperationCostKwh !== 0 ? localState?.cenaeOperationCostKwh : matchingItem.costPerKwh,
                            totalAmount: localState?.cenaeOperationTotalAmount !== 0 ? localState?.cenaeOperationTotalAmount : matchingItem.totalAmount,
                            billDetailId: matchingItem.billDetailId,
                        };
                    case 5:
                        return {
                            ...item1,
                            energyCostId: matchingItem.energyCostId,
                            costPerKwh: localState?.generationBCostKwh !== 0 ? localState?.generationBCostKwh : matchingItem.costPerKwh,
                            totalAmount: localState?.generationBTotalAmount !== 0 ? localState?.generationBTotalAmount : matchingItem.totalAmount,
                            billDetailId: matchingItem.billDetailId,
                        };
                    case 6:
                        return {
                            ...item1,
                            energyCostId: matchingItem.energyCostId,
                            costPerKwh: localState?.generationICostKwh !== 0 ? localState?.generationICostKwh : matchingItem.costPerKwh,
                            totalAmount: localState?.generationITotalAmount !== 0 ? localState?.generationITotalAmount : matchingItem.totalAmount,
                            billDetailId: matchingItem.billDetailId,
                        };
                    case 7:
                        return {
                            ...item1,
                            energyCostId: matchingItem.energyCostId,
                            costPerKwh: localState?.generationPCostKwh !== 0 ? localState?.generationPCostKwh : matchingItem.costPerKwh,
                            totalAmount: localState?.generationPTotalAmount !== 0 ? localState?.generationPTotalAmount : matchingItem.totalAmount,
                            billDetailId: matchingItem.billDetailId,
                        };
                    case 8:
                        return {
                            ...item1,
                            energyCostId: matchingItem.energyCostId,
                            costPerKw: localState?.capacityCostKw !== 0 ? localState?.capacityCostKw : matchingItem.costPerKw,
                            totalAmount: localState?.capacityTotalAmount !== 0 ? localState?.capacityTotalAmount : matchingItem.totalAmount,
                            billDetailId: matchingItem.billDetailId,
                        };
                    case 9:
                        return {
                            ...item1,
                            energyCostId: matchingItem.energyCostId,
                            costPerKwh: localState?.memCostKwh !== 0 ? localState?.memCostKwh : matchingItem.costPerKwh,
                            totalAmount: localState?.memTotalAmount !== 0 ? localState?.memTotalAmount : matchingItem.totalAmount,
                            billDetailId: matchingItem.billDetailId,
                        };
                    default:
                        return item1;
                }
            } else {
                let baseObject = {};
                switch (item1.conceptId) {
                    case 1:
                        baseObject = { ...item1, billDetailId: 0, energyCostId: 0, cost: localState?.supplyCost, totalAmount: localState?.supplyTotalAmount };
                        Object.assign(baseSupply, baseObject);
                        break;
                    case 2:
                        baseObject = { ...item1, billDetailId: 0, energyCostId: 0, costPerKw: localState?.distributionCostKw, totalAmount: localState?.distributionTotalAmount };
                        Object.assign(baseDistribution, baseObject);
                        break;
                    case 3:
                        baseObject = { ...item1, billDetailId: 0, energyCostId: 0, costPerKwh: localState?.transmitionCostKwh, totalAmount: localState?.transmitionTotalAmount };
                        Object.assign(baseTransmition, baseObject);
                        break;
                    case 4:
                        baseObject = { ...item1, billDetailId: 0, energyCostId: 0, costPerKwh: localState?.cenaeOperationCostKwh, totalAmount: localState?.cenaeOperationTotalAmount };
                        Object.assign(baseCenace, baseObject);
                        break;
                    case 5:
                        baseObject = { ...item1, billDetailId: 0, energyCostId: 0, costPerKwh: localState?.generationBCostKwh, totalAmount: localState?.generationBTotalAmount };
                        Object.assign(baseGenerationB, baseObject);
                        break;
                    case 6:
                        baseObject = { ...item1, billDetailId: 0, energyCostId: 0, costPerKwh: localState?.generationICostKwh, totalAmount: localState?.generationITotalAmount };
                        Object.assign(baseGenerationI, baseObject);
                        break;
                    case 7:
                        baseObject = { ...item1, billDetailId: 0, energyCostId: 0, costPerKwh: localState?.generationPCostKwh, totalAmount: localState?.generationPTotalAmount };
                        Object.assign(baseGenerationP, baseObject);
                        break;
                    case 8:
                        baseObject = { ...item1, billDetailId: 0, energyCostId: 0, costPerKw: localState?.capacityCostKw, totalAmount: localState?.capacityTotalAmount };
                        Object.assign(baseCapacity, baseObject);
                        break;
                    case 9:
                        baseObject = { ...item1, billDetailId: 0, energyCostId: 0, costPerKwh: localState?.memCostKwh, totalAmount: localState?.memTotalAmount };
                        Object.assign(baseMem, baseObject);
                        break;
                    default:
                        return item1;
                }
                return baseObject;
            }
        });

        return combined;
    }


    useEffect(() => {
        if (electiricityTariff) {
            // setValueCost(electricityTariff.data);
            if (receiptId && data.receiptDetailId) {
                electricityCost.refetch();
            }
        }
    }, [electiricityTariff]);

    //Genera la combinacion las tarifas con los costos
    useEffect(() => {
        if (electricityCost.isSuccess && electricityCost.data) {
            let aux = [];
            listenergyCost.map((value) => {
                aux.push(handleCombineteData(value.receiptDetailId));
            });
            setCombineTotal(aux)
            //if(isVisibility) setCombinedData(handleCombineteData(data.receiptDetailId));
        }
    }, [electricityCost.isSuccess]);

    //CALCULOS
    const CalcCriterio = (consumoBase, consumoIntermedia, consumoPunta, demandaBase, demandaIntermedia, demandaPunta) => {
        //Obtener valor del local state
        const { billedDays } = localState;
        //Criterios
        const { capacityA, capacityB, distributionA, distributionB } = getCriteria(
            validateNumber(Number(consumoBase)),
            validateNumber(Number(consumoIntermedia)),
            validateNumber(Number(consumoPunta)),
            validateNumber(Number(demandaPunta)),
            validateNumber(Number(billedDays)),
            validateNumber(Number(demandaBase)),
            validateNumber(Number(demandaIntermedia))
        );

        return { capacityA, capacityB, distributionA, distributionB }
    }

    //useEffect(() => {
    const CalcsConsumo = (ch_totalConsumption, kvarh) => {
        const angle = Math.atan(validateNumber(kvarh) === 0 ? 0 : kvarh / ch_totalConsumption);
        const degrees = toDegrees(angle);
        const radians = toRadians(degrees);
        const cosValue = Math.cos(radians);
        const roundedPowerFactor = validateNumber(kvarh) === 0 ? 0 : Math.round(cosValue * 100 * 100) / 100;

        return { factorPotencia: roundedPowerFactor };
    }

    // Costo de Suministro
    const CalsSuministro = () => {
        let supplyCost = 0;
        let supplyTotalAmount = 0;

        if (isDoublePeriod === 1) {
            supplyCost = priceEnergy.supplyValue;
            supplyTotalAmount = priceEnergy.supplyValue;
        } else if (isDoublePeriod === 2 && data.tariffTypeId === 2) {
            supplyCost = validateNumber(priceEnergy.supplyValue * localState?.billedDays / billedDays);
            supplyTotalAmount = supplyCost;
        } else if (isDoublePeriod === 2 && data.tariffTypeId === 3) {
            supplyCost = validateNumber(priceEnergy.supplyValue * localState?.billedDays / billedDays);
            supplyTotalAmount = supplyCost;
        }

        return { supplyCost, supplyTotalAmount };
    }

    //Distribucion
    const CalsDistribucion = (distributionA, distributionB) => {
        let distribution_priceKw = 0;

        if (isDoublePeriod === 1) {
            distribution_priceKw = priceEnergy.distributionValue * Math.min(Number(distributionA), Number(distributionB));
        } else if (isDoublePeriod === 2 && data.tariffTypeId === 2) { // invierno
            distribution_priceKw = validateNumber(priceEnergy.distributionValue * localState.billedDays * Math.min(Number(distributionA), Number(distributionB)) / billedDays);
        } else if (isDoublePeriod === 2 && data.tariffTypeId === 3) { // verano
            distribution_priceKw = validateNumber(priceEnergy.distributionValue * (24 * localState.billedDays) * Math.min(Number(distributionA), Number(distributionB)) / (billedDays * 24));
        }

        return { distributionCostKw: distribution_priceKw, distributionTotalAmount: distribution_priceKw };
    }

    //Transmision
    const CalcsTrans = (ch_totalConsumption) => {
        // Calcula el precio de transmisión
        const transmition_priceKwh = validateNumber(priceEnergy.transmitionValue * ch_totalConsumption);

        return { transmitionCostKwh: transmition_priceKwh, transmitionTotalAmount: transmition_priceKwh };
    }

    //CENACE
    const CalcsCENACE = (ch_totalConsumption) => {
        // Calcula el costo de operación de CENACE
        const cenaeOperation_priceKwh = validateNumber(priceEnergy.cenaeOperationValue * ch_totalConsumption);

        return { cenaeOperationCostKwh: cenaeOperation_priceKwh, cenaeOperationTotalAmount: cenaeOperation_priceKwh };
    }

    //Generacion B
    const CalcsGeneracionB = (consumoBase) => {
        // Calcula el costo de generación B
        const generationB_priceKwh = validateNumber(priceEnergy.generationBValue * Number(consumoBase));

        return { generationBCostKwh: generationB_priceKwh, generationBTotalAmount: generationB_priceKwh };
    }

    //Generacion I
    const CalcsGeneracionI = (consumoIntermedia) => {
        // Calcula el costo de generación I
        const generationI_priceKwh = validateNumber(priceEnergy.generationIValue * Number(consumoIntermedia));

        return { generationICostKwh: generationI_priceKwh, generationITotalAmount: generationI_priceKwh };
    }

    //Generacion P
    const CalcsGeneracionP = (consumoPunta) => {
        const generationP_priceKwh = validateNumber(priceEnergy.generationPValue * Number(consumoPunta));

        return { generationPCostKwh: generationP_priceKwh, generationPTotalAmount: generationP_priceKwh };
    }

    // Capacidad
    const CalcsCapacidad = (capacityA, capacityB) => {
        let capacity_priceKw = 0;
        let capacityCostKw = 0;
        let capacityTotalAmount = 0;

        if (isDoublePeriod === 1) {
            capacity_priceKw = validateNumber(priceEnergy.capacityValue * Math.min(Number(capacityA), Number(capacityB)));
            capacityCostKw = capacity_priceKw;
            capacityTotalAmount = capacity_priceKw;
        } else if (isDoublePeriod === 2 && data.tariffTypeId === 2) { // invierno
            capacity_priceKw = validateNumber(priceEnergy.capacityValue * localState.billedDays * Math.min(Number(capacityA), Number(capacityB)) / billedDays);
            capacityCostKw = capacity_priceKw;
            capacityTotalAmount = capacity_priceKw;
        } else if (isDoublePeriod === 2 && data.tariffTypeId === 3) { // verano
            capacity_priceKw = validateNumber(priceEnergy.capacityValue * localState.billedDays * Math.min(Number(capacityA), Number(capacityB)) / billedDays);
            capacityCostKw = capacity_priceKw;
            capacityTotalAmount = capacity_priceKw;
        }

        return { capacityCostKw: capacityCostKw, capacityTotalAmount: capacityTotalAmount };
    }

    // SCnMEM
    const CalcsSCNMEM = (ch_totalConsumption) => {
        const mem_priceKwh = validateNumber(priceEnergy.memValue * ch_totalConsumption);

        return { memCostKwh: mem_priceKwh, memTotalAmount: mem_priceKwh };
    }

    // Costo total
    const CalcsTotal = (supplyCost, distributionCostKw, capacityCostKw, transmitionCostKwh, cenaeOperationCostKwh, generationBCostKwh, generationICostKwh, generationPCostKwh, memCostKwh) => {
        const sumIfValid = (value) => validateNumber(value);

        const totalPrice = sumIfValid(supplyCost);
        const totalPriceKw = sumIfValid(distributionCostKw) + sumIfValid(capacityCostKw);
        const totalPriceKwh = sumIfValid(transmitionCostKwh) +
            sumIfValid(cenaeOperationCostKwh) +
            sumIfValid(generationBCostKwh) +
            sumIfValid(generationICostKwh) +
            sumIfValid(generationPCostKwh) +
            sumIfValid(memCostKwh);

        const granTotal = totalPrice + totalPriceKw + totalPriceKwh;

        return { totalPrice, totalPriceKw, totalPriceKwh, granTotal };
    };

    //useEffect(() => {
    const CalcsFactorPotencia = (ch_totalKw, supplyCost, factorPotencia) => {
        const ch_powerFactorCalculationRule = getPowerFactorCalculationRule(ch_totalKw);
        const ch_minimoMensual = validateNumber(Number(supplyCost));
        const ch_powerFactorBonus = validateNumber(calculatePowerFactorBonus(ch_powerFactorCalculationRule, Number(factorPotencia)));
        const ch_powerFactorPenalty = validateNumber(calculatePowerFactorPenalty(Number(factorPotencia), Number(ch_powerFactorCalculationRule)));

        return { ch_totalKw, ch_powerFactorCalculationRule, ch_minimoMensual, ch_powerFactorBonus, ch_powerFactorPenalty };
    }

    //Obtiene el consumo total
    const getTotalConsumption = (consumoBase, consumoIntermedia, consumoPunta) => {
        const base = validateNumber(Number(consumoBase));
        const intermedia = validateNumber(Number(consumoIntermedia));
        const punta = validateNumber(Number(consumoPunta));

        const totalConsumption = base + intermedia + punta;

        return totalConsumption;
    };


    const handleCalcs = (value, fieldName) => {

        const consumoBase = validateNumber(Number((fieldName === "consumoBase") ? value : localState.consumoBase));
        const consumoIntermedia = validateNumber(Number((fieldName === "consumoIntermedia") ? value : localState.consumoIntermedia));
        const consumoPunta = validateNumber(Number((fieldName === "consumoPunta") ? value : localState.consumoPunta));
        const demandaBase = validateNumber(Number((fieldName === "demandaBase") ? value : localState.demandaBase));
        const demandaIntermedia = validateNumber(Number((fieldName === "demandaIntermedia") ? value : localState.demandaIntermedia));
        const demandaPunta = validateNumber(Number((fieldName === "demandaPunta") ? value : localState.demandaPunta));
        const kvarh = validateNumber(Number((fieldName === "kvarh") ? value : localState.kvarh));
        const facpotencia = validateNumber(Number((fieldName === "factorPotencia") ? value : localState.factorPotencia));
        const totalKw = validateNumber(Number(localState?.totalKw));

        const ch_totalConsumption = getTotalConsumption(consumoBase, consumoIntermedia, consumoPunta);
        const criterio = CalcCriterio(consumoBase, consumoIntermedia, consumoPunta, demandaBase, demandaIntermedia, demandaPunta);
        const suministro = CalsSuministro();
        const distribucion = CalsDistribucion(criterio.distributionA, criterio.distributionB);
        const capacidad = CalcsCapacidad(criterio.capacityA, criterio.capacityB);
        const transmision = CalcsTrans(ch_totalConsumption);
        const cenace = CalcsCENACE(ch_totalConsumption);
        const generacionB = CalcsGeneracionB(consumoBase);
        const consumo = CalcsConsumo(ch_totalConsumption, kvarh);
        const generacionI = CalcsGeneracionI(consumoIntermedia);
        const generacionP = CalcsGeneracionP(consumoPunta);
        const scnmem = CalcsSCNMEM(ch_totalConsumption);
        const total = CalcsTotal(
            suministro.supplyCost,
            distribucion.distributionCostKw,
            capacidad.capacityCostKw,
            transmision.transmitionCostKwh,
            cenace.cenaeOperationCostKwh,
            generacionB.generationBCostKwh,
            generacionI.generationICostKwh,
            generacionP.generationPCostKwh,
            scnmem.memCostKwh
        );

        const factorpotencia = CalcsFactorPotencia(totalKw, suministro.supplyCost, consumo.factorPotencia);

        const combinedSettings = JSON.parse(JSON.stringify({
            ...localState,
            consumoBase: validateNumber(consumoBase),
            consumoIntermedia: validateNumber(consumoIntermedia),
            consumoPunta: validateNumber(consumoPunta),
            demandaBase: validateNumber(demandaBase),
            demandaIntermedia: validateNumber(demandaIntermedia),
            demandaPunta: validateNumber(demandaPunta),
            kvarh: validateNumber(kvarh),
            factorPotencia: validateNumber(facpotencia),
            ...criterio,
            ...suministro,
            ...distribucion,
            ...capacidad,
            ...transmision,
            ...cenace,
            ...generacionB,
            ...consumo,
            ...generacionI,
            ...generacionP,
            ...scnmem,
            ...total,
            ...factorpotencia,
            // ...receiptDetailCalculation
        }));

        setLocalState(combinedSettings);
        //setCombinedData(handleCombineteData(data.receiptDetailId));
    }

    useEffect(() => {
        if (localState && data?.receiptDetailId) {
            const combinedData = handleCombineteData(data.receiptDetailId);
            if (combinedData) {
                setCombinedData(combinedData);
            } else {
                console.error("handleCombineteData returned undefined");
            }
        }
    }, [localState, data?.receiptDetailId]);


    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ backgroundColor: `${color}60`, padding: 2, borderRadius: '4px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Consumo (KWh)</Typography>
                            <Box mt={2}>
                                <Typography variant="subtitle2" gutterBottom>Base</Typography>
                                <StyledTextField
                                    fullWidth
                                    value={localState?.consumoBase ?? data?.baseConsumption}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const pattern = /^\d{0,6}(?:\.\d{0,4})?$/;
                                        if (pattern.test(value) || value === "") {
                                            handleCalcs(value, 'consumoBase');
                                        }
                                    }}
                                    disabled={isVisibility}
                                />
                            </Box>
                            <Box mt={2}>
                                <Typography variant="subtitle2" gutterBottom>Intermedia</Typography>
                                <StyledTextField
                                    fullWidth
                                    value={localState?.consumoIntermedia ?? data?.intermediateConsumption}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const pattern = /^\d{0,6}(?:\.\d{0,4})?$/;
                                        if (pattern.test(value) || value === "") {
                                            handleCalcs(value, 'consumoIntermedia');
                                        }
                                    }}
                                    disabled={isVisibility}
                                />
                            </Box>
                            <Box mt={2}>
                                <Typography variant="subtitle2" gutterBottom>Punta</Typography>
                                <StyledTextField
                                    fullWidth
                                    value={localState?.consumoPunta ?? data?.peakConsumption}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const pattern = /^\d{0,6}(?:\.\d{0,4})?$/;
                                        if (pattern.test(value) || value === "") {
                                            handleCalcs(value, 'consumoPunta');
                                        }
                                    }}
                                    disabled={isVisibility}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Demanda (KW)</Typography>
                            <Box mt={2}>
                                <Typography variant="subtitle2" gutterBottom>Base</Typography>
                                <StyledTextField
                                    fullWidth
                                    value={localState?.demandaBase ?? data?.baseDemand}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const pattern = /^\d{0,6}(?:\.\d{0,4})?$/;
                                        if (pattern.test(value) || value === "") {
                                            handleCalcs(value, 'demandaBase');
                                        }
                                    }}
                                    disabled={isVisibility}
                                />
                            </Box>
                            <Box mt={2}>
                                <Typography variant="subtitle2" gutterBottom>Intermedia</Typography>
                                <StyledTextField
                                    fullWidth
                                    value={localState?.demandaIntermedia ?? data?.intermediateDemand}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const pattern = /^\d{0,6}(?:\.\d{0,4})?$/;
                                        if (pattern.test(value) || value === "") {
                                            handleCalcs(value, 'demandaIntermedia');
                                        }
                                    }}
                                    disabled={isVisibility}
                                />
                            </Box>
                            <Box mt={2}>
                                <Typography variant="subtitle2" gutterBottom>Punta</Typography>
                                <StyledTextField
                                    fullWidth
                                    value={localState?.demandaPunta ?? data?.peakDemand}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const pattern = /^\d{0,6}(?:\.\d{0,4})?$/;
                                        if (pattern.test(value) || value === "") {
                                            handleCalcs(value, 'demandaPunta');
                                        }
                                    }}
                                    disabled={isVisibility}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Información complementaria</Typography>
                            <Box mt={2}>
                                <Typography variant="subtitle2" gutterBottom>Reactivos (KVArh)</Typography>
                                <StyledTextField
                                    fullWidth
                                    value={localState?.kvarh ?? data?.reactivePower}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const pattern = /^\d{0,6}(?:\.\d{0,4})?$/;
                                        if (pattern.test(value) || value === "") {
                                            handleCalcs(value, 'kvarh');
                                        }
                                    }}
                                    disabled={isVisibility}
                                />
                            </Box>
                            <Box mt={2}>
                                <Typography variant="subtitle2" gutterBottom>Factor de potencia</Typography>
                                <StyledTextFieldReadOnly
                                    fullWidth
                                    value={localState?.factorPotencia ?? data?.powerFactor}
                                    inputProps={{ readOnly: true }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const pattern = /^\d{0,6}(?:\.\d{0,4})?$/;
                                        if (pattern.test(value) || value === "") {
                                            handleCalcs(value, 'factorPotencia');
                                        }
                                    }}
                                    disabled={isVisibility}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Criterios</Typography>
                            <Box mt={2}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Capacidad A: {localState?.capacityA ?? data?.criteria?.[0]?.capacityA}</Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Distribución A: {localState?.distributionA ?? data?.criteria?.[0]?.distributionA}</Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Capacidad B: {localState?.capacityB ?? data?.criteria?.[0]?.capacityB}</Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Distribución B: {localState?.distributionB ?? data?.criteria?.[0]?.distributionB}</Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>KWHMax: {localState?.distributionA ?? data?.criteria?.[0]?.distributionA}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 4 }}>
                <ViewSection
                    title={(<Typography variant="subtitle1">Tarifas</Typography>)}
                    divider={(<Divider />)}
                >
                    <Grid container>
                        <Grid item container justifyContent={"flex-end"} alignItems={"center"}>
                            <TariffTableDetail data={combinedData} isDoublePeriod={isDoublePeriod} quoteId={quoteId} />
                        </Grid>
                    </Grid>
                </ViewSection>
            </Grid>
        </Grid>
    )
};
