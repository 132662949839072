import { calculatePowerFactor, calculatePowerFactorBonus, calculatePowerFactorPenalty, getCriteria, getPowerFactorCalculationRule, toDegrees, toRadians } from "../../helpers/calculation";

export const calculateReceiptCalculation = (
  energyCost,
  initialVariables,
  exchangeRate,
  batteryInitialCalculation,
  breakdownOfAmountToPay,
  invoiceTotal,
  calculation,
  actualState,
  lowVoltage2pValue,
) => {
  const { SAECapacityKwh, SAECapacityKw, supplyPrice, totalPriceKw, totalPriceKwh, utilityPercent } = initialVariables;
  const { lightingFee } = breakdownOfAmountToPay
  const { indirectCosts } = batteryInitialCalculation;
  const { initialExpensePercent, residualValuePercent, annualInterestRate, periodMonths } = calculation;
  const { ch_powerFactorBonus: powerFactorBonus, ch_powerFactorPenalty: powerFactorPenalty, factorPotencia: factorPotenciaValue, ch_powerFactorCalculationRule: powerFactorCalculationRuleValue } = actualState;
  //Inversion Requerida
  const requiredInvestmentUSD = energyCost * SAECapacityKwh;
  const requiredInvestmentMXN = requiredInvestmentUSD * exchangeRate;

  // Mantenimiento
  const maintenanceUSD = 0.02;
  const maintenanceAnnualUSD = maintenanceUSD * (SAECapacityKw * 1000)
  const maintenanceAnnualMXN = maintenanceAnnualUSD * exchangeRate;

  // Costo de operación
  const operationCostConfig = indirectCosts.find(x => x.description === "Costos operativos");
  const installationCostConfig = indirectCosts.find(x => x.description === "Costos de instalación");
  const civilWorksCostConfig = indirectCosts.find(x => x.description === "Costos de obra civil");
  const otherExpensesConfig = indirectCosts.find(x => x.description === "Otros gastos");

  const installationCost = installationCostConfig.typeValue === "1" ? requiredInvestmentMXN * (installationCostConfig.value / 100) : installationCostConfig.value;

  const civilWorkCost = civilWorksCostConfig.typeValue === "1" ? requiredInvestmentMXN * (civilWorksCostConfig.value / 100) : civilWorksCostConfig.value;

  const otherExpenses = otherExpensesConfig.typeValue === "1" ? requiredInvestmentMXN * (otherExpensesConfig.value / 100) : otherExpensesConfig.value;

  const maintenance = maintenanceAnnualMXN * 1.15;

  const operatingCost = operationCostConfig.typeValue === "1" ? requiredInvestmentMXN * (operationCostConfig.value / 100) : operationCostConfig.value;


  //Cargo fijo -> Formula = (Suministro)
  const fixedLoadTp = supplyPrice;

  //Energia -> Formula = (Transmision + CENAE + Generacion B + Generacion I + Generacion P + SCnMEM)
  const energyTP = totalPriceKw + totalPriceKwh;

  //2% Baja tension -> Formula = (Medicion == 0 ? 0 : 0.02 * (Cargo fijo + Energia))
  //decimal lowVoltageTP = billDetails.Medicion == 0 ? 0 : 0.02m * (fixedLoadTp + energyTP);        
  const lowVoltageTP = lowVoltage2pValue;

  //Factor de potencia -> Formula = (Factor de potencia > 90 ? -1 * (Bonificacion factor de potencia * Suma(Cargo fijo, Energia)) : Penalizacion factor de potencia * Suma(Cargo fijo, Energia))
  const powerFactorTP = calculatePowerFactor(factorPotenciaValue, fixedLoadTp, energyTP, lowVoltageTP, powerFactorBonus, powerFactorPenalty, powerFactorCalculationRuleValue);

  //Subtotal -> Formula = (Cargo fijo + Energia + 2% Baja tension + Factor de potencia)
  const subtotalTp = fixedLoadTp + energyTP + lowVoltageTP + powerFactorTP;

  //IVA -> Formula = (Subtotal * 0.16)
  const IvaTp = subtotalTp * 0.16;

  //Facturacion periodo -> Formula = (Subtotal + IVA)
  const invoicingPeriodTotalTp = subtotalTp + IvaTp;

  const invoiceTotalCapacity = invoicingPeriodTotalTp + lightingFee;

  //Inversion SAE Requerida
  const requiredInvestmentSAEUSD = requiredInvestmentUSD;
  const requiredInvestmentSAEMXN = requiredInvestmentSAEUSD * exchangeRate;

  //Precio Contado
  const roiMXN = (1 - utilityPercent / 100) !== 0 ? (requiredInvestmentMXN + installationCost + civilWorkCost + otherExpenses + maintenance + operatingCost) / (1 - utilityPercent / 100) : 0;
  const roiUSD = roiMXN / exchangeRate;
  const invoiceRealTotal = invoiceTotalCapacity - invoiceTotal
  const monthllyRoi = invoiceRealTotal !== 0 ? roiMXN / (invoiceRealTotal * -1) : 0;
  const annualRoi = monthllyRoi / 12;
  const utilityMxn = roiMXN * (utilityPercent / 100);
  const utilityMxnPercent = (utilityMxn / (requiredInvestmentSAEMXN + installationCost + civilWorkCost + otherExpenses + maintenance)) * 100;

  //Arrendamiento(Leasing)
  const leasingPrice = roiMXN;

  let initialExpense = 0;
  let initialPaymentUSD = 0;
  let initialPaymentMXN = 0;

  if (initialExpensePercent) {
    initialExpense = (initialExpensePercent / 100) * leasingPrice;
    initialPaymentUSD = initialExpense / exchangeRate
    initialPaymentMXN = initialExpense
  }

  let residualValue = 0;
  let finalPaymentUSD = 0;
  let finalPaymentMXN = 0;

  if (residualValuePercent) {
    residualValue = (residualValuePercent / 100) * leasingPrice;

    finalPaymentMXN = residualValue;
    finalPaymentUSD = finalPaymentMXN / exchangeRate;
  }

  let monthlyPaymentUSD = 0;
  let monthlyPaymentMXN = 0;
  if (annualInterestRate) {
    monthlyPaymentMXN = validateNumber(PAGO(annualInterestRate / 100, periodMonths, (leasingPrice - initialExpense) * -1, residualValue));
    monthlyPaymentUSD = monthlyPaymentMXN / exchangeRate;
  }

  const total = finalPaymentMXN + initialPaymentMXN + (monthlyPaymentMXN * periodMonths);
  const netProfit = ((total / (requiredInvestmentSAEMXN + installationCost + civilWorkCost + otherExpenses)) - 1) * 100;


  return {
    roiMXN,
    roiUSD,
    monthllyRoi,
    annualRoi,
    requiredInvestmentSAEUSD,
    requiredInvestmentSAEMXN,
    utilityMxn,
    utilityMxnPercent,
    leasingPrice,
    requiredInvestmentUSD,
    requiredInvestmentMXN,
    maintenanceAnnualUSD,
    maintenanceAnnualMXN,
    invoiceTotalCapacity,
    installationCost,
    civilWorkCost,
    otherExpenses,
    initialExpensePercent,
    residualValuePercent,
    annualInterestRate,
    periodMonths,
    initialExpense,
    residualValue,
    initialPaymentUSD,
    initialPaymentMXN,
    finalPaymentUSD,
    finalPaymentMXN,
    monthlyPaymentUSD,
    monthlyPaymentMXN,
    total,
    netProfit
  };
}


/**
 * Calcula el pago periódico (PMT) de una anualidad, replicando la función PAGO de Excel.
 * @param {number} annualRate - Tasa de interés anual (por ejemplo, 0.10 para 10%).
 * @param {number} periodInMonths - Número total de períodos (meses).
 * @param {number} initialCost - Valor presente (gasto inicial, negativo).
 * @param {number} residualValue - Valor residual (futuro) después del último pago (por defecto 0).
 * @param {number} paymentTiming - Indica si los pagos se realizan al final (0, por defecto) o al principio (1) del período.
 * @returns {number} El pago periódico (flujo de salida, negativo por convención).
 */
export function PAGO(annualRate, periodInMonths, initialCost, residualValue = 0, paymentTiming = 0) {
  try {
    const monthlyRate = annualRate / 12;

    if (monthlyRate === 0) {
      return -(initialCost - residualValue) / periodInMonths;
    }

    const power = Math.pow(1 + monthlyRate, periodInMonths);

    const numerator = monthlyRate * (initialCost * power + residualValue);
    const denominator = power - 1;

    let payment = numerator / denominator;

    // Si los pagos se realizan al principio del período, ajustar el pago
    if (paymentTiming === 1) {
      payment /= (1 + monthlyRate);
    }

    return -payment; // Flujo de salida negativo
  } catch (error) {
    console.error("Error calculando el pago:", error);
    return 0;
  }
}


export const validateNumber = (value) => {
  if (isNaN(value) || !isFinite(value)) {
    return 0;
  }
  return value;
};


// Costo de Suministro 
export const CalsSuministro = (isDoublePeriod, supplyValue, tariffTypeId, daysBilledPeriod, billedDays) => {
  let supplyCost = 0;
  let supplyTotalAmount = 0;

  if (isDoublePeriod === 1) {
    supplyCost = supplyValue;
    supplyTotalAmount = supplyValue;
  } else if (isDoublePeriod === 2 && tariffTypeId === 2) {
    supplyCost = validateNumber(supplyValue * daysBilledPeriod / billedDays);
    supplyTotalAmount = supplyCost;
  } else if (isDoublePeriod === 2 && tariffTypeId === 3) {
    supplyCost = validateNumber(supplyValue * daysBilledPeriod / billedDays);
    supplyTotalAmount = supplyCost;
  }

  return { supplyCost, supplyTotalAmount };
}

// Criterios de Calculo
export const CalcsCritera = (consumoBase, consumoIntermedia, consumoPunta, demandaPunta, billedDays, demandaBase, demandaIntermedia) => {
  const { capacityA, capacityB, distributionA, distributionB } = getCriteria(
    validateNumber(Number(consumoBase)),
    validateNumber(Number(consumoIntermedia)),
    validateNumber(Number(consumoPunta)),
    validateNumber(Number(demandaPunta)),
    validateNumber(Number(billedDays)),
    validateNumber(Number(demandaBase)),
    validateNumber(Number(demandaIntermedia))
  );

  return {
    capacityA,
    capacityB,
    distributionA,
    distributionB
  };
}

//Obtiene el consumo total
export const getTotalConsumption = (consumoBase, consumoIntermedia, consumoPunta) => {
  const base = validateNumber(Number(consumoBase));
  const intermedia = validateNumber(Number(consumoIntermedia));
  const punta = validateNumber(Number(consumoPunta));

  const totalConsumption = base + intermedia + punta;

  return totalConsumption;
};

//Distribucion
export const CalsDistribucion = (isDoublePeriod, distributionValue, tariffTypeId, distributionA, distributionB, daysBilledPeriod, billedDays) => {
  let distribution_priceKw = 0;

  if (isDoublePeriod === 1) {
    distribution_priceKw = distributionValue * Math.min(Number(distributionA), Number(distributionB));
  } else if (isDoublePeriod === 2 && tariffTypeId === 2) { // invierno
    distribution_priceKw = validateNumber(distributionValue * daysBilledPeriod * Math.min(Number(distributionA), Number(distributionB)) / billedDays);
  } else if (isDoublePeriod === 2 && tariffTypeId === 3) { // verano
    distribution_priceKw = validateNumber(distributionValue * (24 * daysBilledPeriod) * Math.min(Number(distributionA), Number(distributionB)) / (billedDays * 24));
  }

  return { distributionCostKw: distribution_priceKw, distributionTotalAmount: distribution_priceKw };
}


// Capacidad
export const CalcsCapacidad = (isDoublePeriod, capacityValue, tariffTypeId, capacityA, capacityB, daysBilledPeriod, billedDays) => {
  let capacity_priceKw = 0;
  let capacityCostKw = 0;
  let capacityTotalAmount = 0;

  if (isDoublePeriod === 1) {
    capacity_priceKw = validateNumber(capacityValue * Math.min(Number(capacityA), Number(capacityB)));
    capacityCostKw = capacity_priceKw;
    capacityTotalAmount = capacity_priceKw;
  } else if (isDoublePeriod === 2 && tariffTypeId === 2) { // invierno
    capacity_priceKw = validateNumber(capacityValue * daysBilledPeriod * Math.min(Number(capacityA), Number(capacityB)) / billedDays);
    capacityCostKw = capacity_priceKw;
    capacityTotalAmount = capacity_priceKw;
  } else if (isDoublePeriod === 2 && tariffTypeId === 3) { // verano
    capacity_priceKw = validateNumber(capacityValue * daysBilledPeriod * Math.min(Number(capacityA), Number(capacityB)) / billedDays);
    capacityCostKw = capacity_priceKw;
    capacityTotalAmount = capacity_priceKw;
  }

  return { capacityCostKw: capacityCostKw, capacityTotalAmount: capacityTotalAmount };
}


//Transmision
export const CalcsTransmision = (ch_totalConsumption, transmitionValue) => {
  // Calcula el precio de transmisión
  const transmition_priceKwh = validateNumber(transmitionValue * ch_totalConsumption);

  return { transmitionCostKwh: transmition_priceKwh, transmitionTotalAmount: transmition_priceKwh };
}

//CENACE
export const CalcsCENACE = (ch_totalConsumption, cenaeOperationValue) => {
  // Calcula el costo de operación de CENACE
  const cenaeOperation_priceKwh = validateNumber(cenaeOperationValue * ch_totalConsumption);

  return { cenaeOperationCostKwh: cenaeOperation_priceKwh, cenaeOperationTotalAmount: cenaeOperation_priceKwh };
}


//Generacion B
export const CalcsGeneracionB = (consumoBase, generationBValue) => {
  // Calcula el costo de generación B
  const generationB_priceKwh = validateNumber(generationBValue * Number(consumoBase));

  return { generationBCostKwh: generationB_priceKwh, generationBTotalAmount: generationB_priceKwh };
}

//Consumo
export const CalcsConsumo = (ch_totalConsumption, kvarh) => {
  const angle = Math.atan(validateNumber(kvarh) === 0 ? 0 : kvarh / ch_totalConsumption);
  const degrees = toDegrees(angle);
  const radians = toRadians(degrees);
  const cosValue = Math.cos(radians);
  const roundedPowerFactor = validateNumber(kvarh) === 0 ? 0 : Math.round(cosValue * 100 * 100) / 100;

  return { factorPotencia: roundedPowerFactor };
}

//Generacion I
export const CalcsGeneracionI = (consumoIntermedia, generationIValue) => {
  // Calcula el costo de generación I
  const generationI_priceKwh = validateNumber(generationIValue * Number(consumoIntermedia));

  return { generationICostKwh: generationI_priceKwh, generationITotalAmount: generationI_priceKwh };
}

//Generacion P
export const CalcsGeneracionP = (consumoPunta, generationPValue) => {
  const generationP_priceKwh = validateNumber(generationPValue * Number(consumoPunta));

  return { generationPCostKwh: generationP_priceKwh, generationPTotalAmount: generationP_priceKwh };
}

// SCnMEM
export const CalcsSCNMEM = (ch_totalConsumption, memValue) => {
  const mem_priceKwh = validateNumber(memValue * ch_totalConsumption);

  return { memCostKwh: mem_priceKwh, memTotalAmount: mem_priceKwh };
}

// Costo total
export const CalcsTotal = (supplyCost, distributionCostKw, capacityCostKw, transmitionCostKwh, cenaeOperationCostKwh, generationBCostKwh, generationICostKwh, generationPCostKwh, memCostKwh) => {
  const sumIfValid = (value) => validateNumber(value);

  const totalPrice = sumIfValid(supplyCost);
  const totalPriceKw = sumIfValid(distributionCostKw) + sumIfValid(capacityCostKw);
  const totalPriceKwh = sumIfValid(transmitionCostKwh) +
    sumIfValid(cenaeOperationCostKwh) +
    sumIfValid(generationBCostKwh) +
    sumIfValid(generationICostKwh) +
    sumIfValid(generationPCostKwh) +
    sumIfValid(memCostKwh);

  const granTotal = totalPrice + totalPriceKw + totalPriceKwh;

  return { totalPrice, totalPriceKw, totalPriceKwh, granTotal };
};


export const CalcsFactorPotencia = (ch_totalKw, supplyCost, factorPotencia) => {
  const ch_powerFactorCalculationRule = getPowerFactorCalculationRule(ch_totalKw);
  const ch_minimoMensual = validateNumber(Number(supplyCost));
  const ch_powerFactorBonus = validateNumber(calculatePowerFactorBonus(ch_powerFactorCalculationRule, Number(factorPotencia)));
  const ch_powerFactorPenalty = validateNumber(calculatePowerFactorPenalty(Number(factorPotencia), Number(ch_powerFactorCalculationRule)));

  return { ch_totalKw, ch_powerFactorCalculationRule, ch_minimoMensual, ch_powerFactorBonus, ch_powerFactorPenalty };
}


export const calculateInitialVariables = (billDetail, batteryInitialCalculation, isSinglePeriod, priceEnergy, electricityPay, utilityPercent) => {
  // const kwhMonthly = billDetail.consumoPunta;
  const kwhMonthly = billDetail.peakConsumption;
  const kwhDaily = kwhMonthly / 30;
  const kwhPeak = batteryInitialCalculation.regionPeak !== 0 ? kwhDaily / batteryInitialCalculation.regionPeak : 0;

  const SAECapacityKw = kwhPeak;
  const SAECapacityKwh = kwhDaily;

  //Demanda Actual 
  // const currentDemand = billDetail.demandaBase + SAECapacityKw;
  const currentDemand = billDetail.baseDemand + SAECapacityKw;
  // const newDemand = currentDemand < billDetail.demandaContratada ? currentDemand : billDetail.demandaContratada;
  const newDemand = currentDemand < billDetail.contractedDemandKw ? currentDemand : billDetail.contractedDemandKw;
  // const differenceDemand = billDetail.demandaContratada - newDemand;
  const differenceDemand = billDetail.contractedDemandKw - newDemand;

  //Demanda contratada
  // const contractedDemandPeakKwh = billDetail.demandaContratada - billDetail.demandaBase;
  const contractedDemandPeakKwh = billDetail.contractedDemandKw - billDetail.baseDemand;
  const contractedDemandDailyKwh = contractedDemandPeakKwh * batteryInitialCalculation.regionPeak;
  const contractedDemandMonthlyKwh = contractedDemandDailyKwh * 30;

  //Demanda diferencia
  const differenceDemandPeakKwh = differenceDemand * -1;
  const differenceDemandDailyKwh = differenceDemandPeakKwh * batteryInitialCalculation.regionPeak;
  const differenceDemandMonthlyKwh = differenceDemandDailyKwh * 30;

  //Consumos
  // const consumptionPeakKwh = billDetail.consumoPunta - contractedDemandDailyKwh < 0 ? differenceDemandPeakKwh : 0;
  const consumptionPeakKwh = billDetail.peakConsumption - contractedDemandDailyKwh < 0 ? differenceDemandPeakKwh : 0;
  // const baseConsumptionKwh = billDetail.consumoBase + billDetail.consumoPunta - consumptionPeakKwh;
  const baseConsumptionKwh = billDetail.baseConsumption + billDetail.peakConsumption - consumptionPeakKwh;
  // const intermediateConsumptionKwh = billDetail.consumoIntermedia;
  const intermediateConsumptionKwh = billDetail.intermediateConsumption;

  //Demandas
  const demandPeakKw = differenceDemandPeakKwh < 0 ? 0 : differenceDemandPeakKwh;
  const demandBaseKw = newDemand;
  // const demandIntermediateKw = billDetail.demandaIntermedia;
  const demandIntermediateKw = billDetail.intermediateDemand;

  //Criterios
  const totalConsumption = baseConsumptionKwh + intermediateConsumptionKwh + consumptionPeakKwh;
  const { capacityA, capacityB, distributionA, distributionB } = getCriteria(baseConsumptionKwh, intermediateConsumptionKwh, consumptionPeakKwh, demandPeakKw, billDetail.billedDays, demandBaseKw, demandIntermediateKw);

  // Costos Energia
  //Suministro -> Formula = (Suministro)

  const supplyPrice = (billDetail?.billedDays && electricityPay?.billedDays) ? (isSinglePeriod
    ? priceEnergy.supplyValue
    : priceEnergy.supplyValue * billDetail.billedDays / electricityPay.billedDays) : 0;

  const distributionPriceKw = (electricityPay &&  electricityPay.idReceiptType) ? (isSinglePeriod
    ? priceEnergy.distributionValue * Math.min(distributionA, distributionB)
    : !isSinglePeriod && electricityPay.idReceiptType === 2
      ? priceEnergy.distributionValue * billDetail.billedDays * Math.min(distributionA, distributionB) / electricityPay.billedDays
      : priceEnergy.distributionValue * (24 * billDetail.billedDays) * Math.min(distributionA, distributionB) / (electricityPay.billedDays * 24)) : 0;

  //Transmision -> Formula = (Transmision * Consumo total)
  const transmitionPriceKwh = priceEnergy?.transmitionValue * totalConsumption;

  //CENAE -> Formula = (CENAE * Consumo total)
  const cenaeOperationPriceKwh = priceEnergy?.cenaeOperationValue * totalConsumption;

  //Generacion B -> Formula = (Generacion B * Consumo base)
  const generationBPriceKwh = priceEnergy?.generationBValue * baseConsumptionKwh;

  //Generacion I -> Formula = (Generacion I * Consumo intermedio)
  const generationIPriceKwh = priceEnergy?.generationIValue * intermediateConsumptionKwh;

  //Generacion P -> Formula = (Generacion P * Consumo punta)
  const generationPPriceKwh = priceEnergy?.generationPValue * consumptionPeakKwh;

  //Capacidad -> Formula = (Capacidad * Min(Capacidad A, Capacidad B))
  const capacityPriceKw = (billDetail.billedDays && electricityPay?.billedDays) ? (isSinglePeriod
    ? priceEnergy.capacityValue * Math.min(capacityA, capacityB)
    : priceEnergy.capacityValue * billDetail.billedDays * Math.min(capacityA, capacityB) / electricityPay.billedDays) : 0;

  //SCnMEM -> Formula = (SCnMEM * Consumo total)
  const memPriceKwh = priceEnergy?.memValue * totalConsumption;

  // Importe a pagar
  const totalPriceKw = distributionPriceKw + capacityPriceKw;
  const totalPriceKwh = transmitionPriceKwh + cenaeOperationPriceKwh + generationBPriceKwh + generationIPriceKwh + generationPPriceKwh + memPriceKwh;

  return {
    kwhMonthly,
    kwhDaily,
    kwhPeak,
    SAECapacityKw,
    SAECapacityKwh,
    supplyPrice,
    totalPriceKw,
    totalPriceKwh,
    utilityPercent: utilityPercent,
    contractedDemandMonthlyKwh,
    differenceDemandMonthlyKwh,
  }
}